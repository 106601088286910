<template>
  <v-row justify="center" align="center">
    <SobreOTesteDesktop/>
    <SobreOTesteMobile/>
    <!-- butão faça o teste  inicio-->
    <v-col cols="10" justify="center" align="center">
      <v-btn
        elevation="0"
        href="/teste-perfil"
        class="mb-10"
        color="error"
        width="270px"
        height="90px"
        style="border-radius: 100px; filter: drop-shadow(0px 0px 7px rgba(232, 93, 117, 0.6));"
      >
        <p
          class="font-weight-thin text-h4 white--text mt-3 text-capitalize"
        >
          Faça
          <small class="text-lowercase text-h4 font-weight-thin">o</small> Teste
        </p>
      </v-btn>
    </v-col>
    <!-- butão faça o teste fim -->
    <v-col cols="12" justify="center" align="center">
      <TiposDePerfilDesktop/>
      <TiposDePerfilMobile/>
    </v-col>
  </v-row>
</template>

<script>
import TiposDePerfilMobile from "../components/TiposDePerfilMobile.vue";
import TiposDePerfilDesktop from "../components/TiposDePerfilDesktop.vue";
import SobreOTesteMobile from "../components/SobreOTesteMobile.vue"
import SobreOTesteDesktop from "../components/SobreOTesteDesktop.vue"
import axios from "axios";

export default {
  name: "Home",
  components: {
    TiposDePerfilMobile,
    TiposDePerfilDesktop,
    SobreOTesteMobile,
    SobreOTesteDesktop,
  },
  data() {
    return {};
  },
  methods: {},
  created() {
    axios.get("https://s3.amazonaws.com/buyco.assets/images/ad2-static.png");
    axios.get("https://s3.amazonaws.com/buyco.assets/images/ad2-gif-min.gif");
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
</style>