<template>
  <div class="hidden-md-and-down">
    <v-row justify="center" align="center">
      <v-col cols="6" xl="4">
        <v-card
          color="primary"
          rounded="xl"
          class="mt-7"
          width="100%"
          height="auto"
          elevation="0"
          style="filter: drop-shadow(0px 0px 5px rgba(52, 60, 89, 0.29))"
        >
          <v-row justify="center" align="center">
            <v-col cols="10" justify="center" align="center">
              <p class="white--text font-weight-bold text-h3">
                Teste de Perfil Comportamental BuyCo<strong
                  class="secondary--text"
                  >.</strong
                >
              </p>
            </v-col>
            <v-col cols="11" justify="center" align="center" class="mt-n5">
              <p class="font-weight-thin white--text text-h5">
                Clique primeiro na opção com características que você mais se identifica e continue até acabar as opções
              </p>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
 
<script>
export default {
  data: () => ({
    perfis: 0,
  }),
};
</script>

<style scoped>
</style>