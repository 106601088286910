<template>
  <div class="d-none d-md-block">
    <v-row justify="center" align="center">
      <v-col cols="12" justify="center" align="center" class="mt-5 mb-n9">
        <p class="font-weight-light text-h5 primary--text mb-10">
          Tipos de Perfil<strong class="secondary--text">:</strong>
        </p>
      </v-col>
      <v-col cols="12" justify="center" align="center">
        <v-carousel
          cycle
          v-model="perfis"
          height="400px"
          class=""
          hide-delimiters
          :show-arrows="false" 
        >
            <!-- executor inicio -->
            <v-carousel-item>
            <v-row justify="center" align="center">
              <v-col cols="6" xl="4">
                <v-card
                  class="mt-8"
                  elevation="0"
                  height="80px"
                  width="100%"
                  color="error"
                  style="
                    filter: drop-shadow(0px 0px 5px rgba(239, 35, 60, 0.3));
                    border-radius: 10px 10px 0px 0px;
                    z-index: 3;
                  "
                >
                  <v-row>
                    <v-col cols="4" justify="center" align="left">
                      <v-img
                        src="../assets/executor.png"
                        height="130px"
                        width="130px"
                        style="bottom: 40px; left: 10px"
                      ></v-img>
                    </v-col>
                    <v-col cols="8" justify="center" align="left">
                      <p
                        class="font-weight-black text-h3 white--text mt-1 ml-5"
                      >
                        Executor
                      </p>
                    </v-col>
                  </v-row>
                </v-card>

                <v-card
                  elevation="0"
                  height="265px"
                  width="100%"
                  color="white"
                  style="
                    filter: drop-shadow(0px 0px 5px rgba(239, 35, 60, 0.2));
                    border-radius: 0px 0px 10px 10px;
                    top: 12px;
                  "
                >
                  <v-row>
                    <v-col cols="5" justify="center" align="left" class="mt-5 ml-15">
                      <p
                        class="font-weight-light grey--text font-italic text-h4"
                      >
                        Desbravador;
                      </p>
                      <p
                        class="
                          font-weight-light
                          grey--text
                          font-italic
                          text-h4
                          mt-n3
                        "
                      >
                        Impulsionador;
                      </p>
                      <p
                        class="
                          font-weight-light
                          grey--text
                          font-italic
                          text-h4
                          mt-n3
                        "
                      >
                        Competidor;
                      </p>
                    </v-col>
                    <v-col cols="5" justify="center" align="left" class="ml-10 mt-5">
                      <p
                        class="
                          font-weight-light
                          grey--text
                          font-italic
                          text-h4
                          
                        "
                      >
                        Direcionador;
                      </p>
                      <p
                        class="
                          font-weight-light
                          grey--text
                          font-italic
                          text-h4
                          mt-n3
                        "
                      >
                        Solucionador;
                      </p>
                      <p
                        class="
                          font-weight-light
                          grey--text
                          font-italic
                          text-h4
                          mt-n3
                        "
                      >
                        Dominante.
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col justify="center" align="center">
                      <p class="font-weight-black error--text text-h5">
                        Perfil do sócio ideal:
                      </p>
                      <p class="font-weight-light error--text text-h5 mt-n5">
                        Planejador
                      </p>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-carousel-item>
          <!-- executor fim -->
          <!-- comunicador inicio -->
            <v-carousel-item>
            <v-row justify="center" align="center">
              <v-col cols="6" xl="4">
                <v-card
                  class="mt-8"
                  elevation="0"
                  height="80px"
                  width="100%"
                  color="#F1B71C"
                  style="
                    filter: drop-shadow(0px 0px 5px rgba(241, 183, 28, 0.24));
                    border-radius: 10px 10px 0px 0px;
                    z-index: 3;
                  "
                >
                  <v-row>
                    <v-col cols="4" justify="center" align="left">
                      <v-img
                        src="../assets/comunicador.png"
                        height="110px"
                        width="110px"
                        style="bottom: 30px; left: 5px"
                      ></v-img>
                    </v-col>
                    <v-col cols="7" justify="center" align="left">
                      <p
                        class="font-weight-black white--text mt-n2 ml-n8"
                        style="font-size: 3em;"
                      >
                        Comunicador
                      </p>
                    </v-col>
                  </v-row>
                </v-card>

                <v-card
                  elevation="0"
                  height="265px"
                  width="100%"
                  color="white"
                  style="
                    filter: drop-shadow(0px 0px 7px rgba(241, 183, 28, 0.20));
                    border-radius: 0px 0px 10px 10px;
                    top:12px;
                  "
                >
                  <v-row>
                    <v-col cols="5" justify="center" align="left" class="ml-15">
                      <p
                        class="font-weight-light grey--text font-italic text-h4 mt-5"
                      >
                        Estimulador;
                      </p>
                      <p
                        class="
                          font-weight-light
                          grey--text
                          font-italic
                          text-h4
                          mt-n3
                        "
                      >
                        Influenciador;
                      </p>
                      <p
                        class="
                          font-weight-light
                          grey--text
                          font-italic
                          text-h4
                          mt-n3
                        "
                      >
                        Articulador;
                      </p>
                      </v-col>
                      <v-col cols="5" justify="center" align="left" class="ml-10">
                      <p
                        class="
                          font-weight-light
                          grey--text
                          font-italic
                          text-h4
                          mt-5
                        "
                      >
                        Participativo;
                      </p>
                      <p
                        class="
                          font-weight-light
                          grey--text
                          font-italic
                          text-h4
                          mt-n3
                        "
                      >
                        Comunicativo;
                      </p>
                      <p
                        class="
                          font-weight-light
                          grey--text
                          font-italic
                          text-h4
                          mt-n3
                        "
                      >
                        Facilitador.
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col justify="center" align="center">
                      <p class="font-weight-black text-h5 secondary--text">
                        Perfil do sócio ideal:
                      </p>
                      <p class="font-weight-light secondary--text text-h5 mt-n5">
                        Analista
                      </p>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-carousel-item>
          <!-- comunicador fim -->
          <!-- planejador inicio -->
          <v-carousel-item>
            <v-row justify="center" align="center">
              <v-col cols="6" xl="4">
                <v-card
                  class="mt-8"
                  elevation="0"
                  height="80px"
                  width="100%"
                  color="#498485"
                  style="
                    filter: drop-shadow(0px 0px 5px rgba(73, 132, 133, 0.3));
                    border-radius: 10px 10px 0px 0px;
                    z-index: 3;
                  "
                >
                  <v-row>
                    <v-col cols="3" justify="center" align="left">
                      <v-img
                        src="../assets/planejador.png"
                        height="170px"
                        width="170px"
                        style="bottom: 60px; left: 14px;"
                      ></v-img>
                    </v-col>
                    <v-col cols="7" justify="center" align="center">
                      <p
                        class="font-weight-black white--text ml-n12 mt-n2"
                        style="font-size: 3em;"
                      >
                        Planejador
                      </p>
                    </v-col>
                  </v-row>
                </v-card>

                <v-card
                  elevation="0"
                  height="265px"
                  width="100%"
                  color="white"
                  style="
                    filter: drop-shadow(0px 0px 5px rgba(73, 132, 133, 0.3));
                    border-radius: 0px 0px 10px 10px;
                    top: 12px;
                  "
                >
                  <v-row>
                    <v-col cols="5" justify="center" align="left" class="ml-15">
                      <p
                        class="font-weight-light grey--text font-italic text-h4 mt-5"
                      >
                        Estável;
                      </p>
                      <p
                        class="
                          font-weight-light
                          grey--text
                          font-italic
                          text-h4
                          mt-n3
                        "
                      >
                        Paciente;
                      </p>
                      <p
                        class="
                          font-weight-light
                          grey--text
                          font-italic
                          text-h4
                          mt-n3
                        "
                      >
                        Calmo;
                      </p>
                      </v-col>
                      <v-col cols="5" justify="center" align="left" class="ml-10">
                      <p
                        class="
                          font-weight-light
                          grey--text
                          font-italic
                          text-h4
                          mt-5
                        "
                      >
                        Ritmo consistente;
                      </p>
                      <p
                        class="
                          font-weight-light
                          grey--text
                          font-italic
                          text-h4
                          mt-n3
                        "
                      >
                        Conservador.
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col justify="center" align="center">
                      <p class="font-weight-black success--text text-h5">
                        Perfil do sócio ideal:
                      </p>
                      <p class="font-weight-light success--text text-h5 mt-n5">
                        Executor
                      </p>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-carousel-item>
          <!-- planejador fim -->
          <!-- analista inicio -->
            <v-carousel-item>
            <v-row justify="center" align="center">
              <v-col cols="6" xl="4">
                <v-card
                  class="mt-8"
                  elevation="0"
                  height="80px"
                  width="100%"
                  color="#4B5781"
                  style="
                    filter: drop-shadow(0px 0px 5px rgba(52, 60, 89, 0.3));
                    border-radius: 10px 10px 0px 0px;
                    z-index: 3;
                  "
                >
                  <v-row>
                    <v-col cols="3" justify="center" align="left">
                      <v-img
                        src="../assets/analista.png"
                        height="130px"
                        width="130px"
                        style="bottom: 42px; left: 5px"
                      ></v-img>
                    </v-col>
                    <v-col cols="7" justify="center" align="center">
                      <p
                        class="font-weight-black text-h3 white--text mr-15 mt-1"
                      >
                        Analista
                      </p>
                    </v-col>
                  </v-row>
                </v-card>

                <v-card
                  elevation="0"
                  height="265px"
                  width="100%"
                  color="white"
                  style="
                    filter: drop-shadow(0px 0px 5px rgba(52, 60, 89, 0.3));
                    border-radius: 0px 0px 10px 10px;
                    top: 12px;
                  "
                >
                  <v-row>
                    <v-col cols="5" justify="center" align="left" class="ml-15">
                      <p
                        class="font-weight-light grey--text font-italic text-h4 mt-5"
                      >
                        Regulador;
                      </p>
                      <p
                        class="
                          font-weight-light
                          grey--text
                          font-italic
                          text-h4
                          mt-n3
                        "
                      >
                        Observador;
                      </p>
                      <p
                        class="
                          font-weight-light
                          grey--text
                          font-italic
                          text-h4
                          mt-n3
                        "
                      >
                        Ordenado;
                      </p>
                      </v-col>
                      <v-col cols="5" justify="center" align="left" class="ml-10">
                      <p
                        class="
                          font-weight-light
                          grey--text
                          font-italic
                          text-h4
                          mt-5
                        "
                      >
                        Criterioso;
                      </p>
                      <p
                        class="
                          font-weight-light
                          grey--text
                          font-italic
                          text-h4
                          mt-n3
                        "
                      >
                        Especialista;
                      </p>
                      <p
                        class="
                          font-weight-light
                          grey--text
                          font-italic
                          text-h4
                          mt-n3
                        "
                      >
                        Idealizador.
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col justify="center" align="center">
                      <p class="font-weight-black primary--text text-h5">
                        Perfil do sócio ideal:
                      </p>
                      <p class="font-weight-light primary--text text-h5 mt-n5">
                        Planejador
                      </p>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-carousel-item>
          <!-- analista fim -->
        </v-carousel>
      </v-col>
      <v-col cols="10" justify="center" align="center" class="mt-n5 mb-8">
        <v-btn class="mx-2" fab dark  color="primary" @click="perfis--">
          <v-icon dark> mdi-chevron-left </v-icon>
        </v-btn>

        <v-icon
          v-for="(perfil, i) in 4"
          :key="i"
          class="mx-2 grey--icon"
          v-bind:class="{ 'primary--text': perfis === i }"
          size="12px"
        >
          mdi-circle
        </v-icon>

        <v-btn class="mx-2" fab dark  color="primary" @click="perfis++">
          <v-icon dark> mdi-chevron-right </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
    data: () => ({
        perfis: 0,
    }),
};
</script>
<style scoped>

</style>