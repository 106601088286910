<template>
  <div class="hidden-md-and-down">
    <v-row justify="center" align="center">
      <v-col cols="8">
        <v-card
          width="100%"
          height="auto"
          color="white"
          class="my-7 mt-12"
          rounded="xl"
          elevation="0"
          style="filter: drop-shadow(0px 0px 5px rgba(52, 60, 89, 0.29))"
        >
          <v-row>
            <v-col justify="center" align="center">
              <p class="font-weight-black text-h4 primary--text mx-2">
                Sobre o Teste de Perfil BuyCo<strong class="secondary--text"
                  >.</strong
                >
              </p>
              <p class="font-weight-light primary--text mx-10 mt-n2">
                O Teste de Perfil empreendedor é um relatório produzido pela BuyCo cuja
                finalidade é orientá-lo no conhecimento de suas características
                empreendedoras. <br> Todo indivíduo tem um perfil de comportamento e
                competências predominante que demonstra sua maneira de agir, de
                se relacionar e tomar decisões. Mas vale lembrar que esse perfil
                pode mudar ao longo do tempo e do contexto, sendo importante
                refazê-lo sempre que necessário.
              </p>
              <!-- <svg
                width="45"
                height="26"
                viewBox="0 0 45 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.881294 0.892611C-0.293766 2.07869 -0.293766 3.99094 0.881294 5.17702L20.8093 25.292C21.7446 26.236 23.2554 26.236 24.1906 25.292L44.1187 5.17702C45.2938 3.99094 45.2938 2.07869 44.1187 0.892609C42.9436 -0.293472 41.0492 -0.293472 39.8741 0.892609L22.488 18.4176L5.10192 0.868408C3.95084 -0.293467 2.03237 -0.29347 0.881294 0.892611Z"
                  fill="#343C59"
                />
              </svg> -->
            </v-col>
          </v-row>
          <v-row justify="center" align="center">
            <v-col cols="10">
              <v-card
                color="white"
                elevation="0"
                rounded="xl"
                width="100%"
                height="130px"
                style="filter: drop-shadow(0px 0px 5px rgba(52, 60, 89, 0.29))"
              >
              <v-row justify="center" align="center">
                  <v-col cols="2" justify="center" align="center">
                      <v-img
                        src="../assets/executor.png"
                        height="6em"
                        width="6em"
                        style="bottom: 5px; left: 0px"
                      ></v-img>
                  </v-col>
                  <v-divider vertical class="mb-6 mt-4 mx-8"></v-divider>
                  <v-col cols="2" justify="center" align="center">
                      <v-img
                        src="../assets/comunicador.png"
                        height="5.5em"
                        width="5.5em"
                        style="bottom: 5px; right: 0px"
                      ></v-img>
                  </v-col>
                  <v-divider vertical class="mb-6 mt-4 mx-8"></v-divider>
                  <v-col cols="2" justify="center" align="center">
                       <v-img
                        src="../assets/planejador.png"
                        height="7.2em"
                        width="7.2em"
                        style="bottom: 5px; left: 0px;"
                      ></v-img>
                  </v-col>
                  <v-divider vertical class="mb-6 mt-4 mx-8"></v-divider>
                  <v-col cols="2" justify="center" align="center">
                       <v-img
                        src="../assets/analista.png"
                        height="6em"
                        width="6em"
                        style="bottom: 10px; left: 0px"
                      ></v-img>
                  </v-col>
              </v-row>
              </v-card>
            </v-col>
            <v-col cols="12" justify="center" align="center">
              <svg
                width="45"
                height="26"
                viewBox="0 0 45 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.881294 0.892611C-0.293766 2.07869 -0.293766 3.99094 0.881294 5.17702L20.8093 25.292C21.7446 26.236 23.2554 26.236 24.1906 25.292L44.1187 5.17702C45.2938 3.99094 45.2938 2.07869 44.1187 0.892609C42.9436 -0.293472 41.0492 -0.293472 39.8741 0.892609L22.488 18.4176L5.10192 0.868408C3.95084 -0.293467 2.03237 -0.29347 0.881294 0.892611Z"
                  fill="#343C59"
                />
              </svg>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
</style>