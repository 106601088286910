<template>
  <div class="d-lg-none">
    <v-row justify="center" align="center">
      <v-col cols="10" md="9" lg="8" xl="6">
        <v-card
          width="100%"
          height="auto"
          color="white"
          class="my-7 mt-12"
          rounded="xl"
          elevation="0"
          style="filter: drop-shadow(0px 0px 5px rgba(52, 60, 89, 0.29))"
        >
          <v-row>
            <v-col justify="center" align="center">
              <p class="font-weight-black text-h4 primary--text mx-2">
                Sobre o Teste de Perfil BuyCo<strong class="secondary--text"
                  >.</strong
                >
              </p>
              <p class="font-weight-light primary--text mx-4 mt-n2">
                O Teste de Perfil empreendedor é um relatório produzido pela BuyCo cuja
                finalidade é orientá-lo no conhecimento de suas características
                empreendedoras. <br> Todo indivíduo tem um perfil de comportamento e
                competências predominante que demonstra sua maneira de agir, de
                se relacionar e tomar decisões. Mas vale lembrar que esse perfil
                pode mudar ao longo do tempo e do contexto, sendo importante
                refazê-lo sempre que necessário.
              </p>
              <svg
                width="45"
                height="26"
                viewBox="0 0 45 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.881294 0.892611C-0.293766 2.07869 -0.293766 3.99094 0.881294 5.17702L20.8093 25.292C21.7446 26.236 23.2554 26.236 24.1906 25.292L44.1187 5.17702C45.2938 3.99094 45.2938 2.07869 44.1187 0.892609C42.9436 -0.293472 41.0492 -0.293472 39.8741 0.892609L22.488 18.4176L5.10192 0.868408C3.95084 -0.293467 2.03237 -0.29347 0.881294 0.892611Z"
                  fill="#343C59"
                />
              </svg>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default{

}
</script>
<style scoped>

</style>