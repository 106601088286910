<template>
  <div>
    <v-app-bar hide-on-scroll color="primary" flat width="100%">
      <div @click="redirectToHome()" class="d-flex align-center" style="cursor: pointer">
        <v-img
          alt="BuyCo Logo"
          class="shrink mt-1 ml-n7"
          contain
          src="https://s3.amazonaws.com/buyco.assets/images/newLogoWhite.png"
          width="12em"
        />
      </div>
      <v-spacer></v-spacer>
      <template class="d-none d-md-block">
        <v-btn
          target
          rounded
          large
          text
          @click="toTeste()"
          class="d-none d-md-block font-weight-thin white--text text-decoration-none text-h5 text-capitalize"
          >Teste de Perfil</v-btn
        >
        <v-divider vertical class="d-none d-md-block mx-2" dark></v-divider>
        <v-btn
          target
          rounded
          large
          text
          @click="toSobre()"
          class="d-none d-md-block font-weight-thin white--text text-decoration-none text-h5 text-capitalize"
          >Sobre os Perfis</v-btn
        >
        <v-divider vertical class="d-none d-md-block mx-2" dark></v-divider>
        <v-btn
          rounded
          large
          text
          @click="whats()"
          class="d-none d-md-block font-weight-thin white--text text-decoration-none text-h5 text-capitalize mr-15"
          >Contato</v-btn
        >
      </template>
      <v-spacer></v-spacer>
      <v-btn
        rounded
        text
        color="white"
        @click.stop="open()"
        class="d-none d-md-block text-capitalize"
      >Acesse Nosso Site</v-btn>
      <v-app-bar-nav-icon
        color="white"
        @click.stop="drawer = !drawer"
        class="d-md-none"
      ></v-app-bar-nav-icon>
    </v-app-bar>
    <v-navigation-drawer
      right
      app
      v-model="drawer"
      absolute
      temporary
      class="d-md-none"
    >
      <v-list nav dense>
        <v-list-item-group active-class="primary--text">
          <v-list-item href="/teste-perfil">
            <v-list-item-title
              ><p class="font-weight-light text-h6">Teste de Perfil</p>
              <p class="font-weight-light text-h6 mt-n6 mb-0">
                Comportamental
              </p></v-list-item-title
            >
          </v-list-item>

          <v-list-item href="/sobre-os-perfis">
            <v-list-item-title
              ><p class="font-weight-light text-h6 mb-0">
                Sobre os perfis
              </p></v-list-item-title
            >
          </v-list-item>

          <v-list-item @click="whats()">
            <v-list-item-title
              ><p class="font-weight-light text-h6 mb-0">
                Contato
              </p></v-list-item-title
            >
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<script>
export default {
  data: () => ({
    drawer: false,
  }),
  methods: {
    whats() {
      window.open(
        `https://api.whatsapp.com/send?phone=+5531984093097`,
        "_blank"
      );
    },
    open() {
      window.open("https://www.buyco.com.br/blog/", "_blank");
    },
    redirectToHome () {
     this.$router.push(
        {
          path: '/',
        }
     )
    },
    toTeste () {
     this.$router.push(
        {
          path: '/teste-perfil',
        }
     )
    },
    toSobre () {
     this.$router.push(
        {
          path: '/sobre-os-perfis',
        }
     )
    },
  },
};
</script>
