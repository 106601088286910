<template>
  <div>
    <HeaderMobile />
    <HeaderDesktop />
    <!-- teste inicio -->
    <v-row justify="center" align="center" class="mt-15">
      <v-col v-if="pageCounter<10" cols="12" justify="center" align="center">
        <p class="font-weight-light primary--text text-h5">
          Você é<strong class="secondary--text">:</strong>
        </p>
      </v-col>
      <v-col v-if="pageCounter==10" cols="10" justify="center" align="center">
        <p class="font-weight-light primary--text text-h5">
          Como você não é, mas o ambiente exige que você seja<strong class="secondary--text">?</strong>
        </p>
      </v-col>
        <!-- 1 inicio-->
      <v-col v-if="pageCounter===0" cols="12" xl="3" lg="4" md="5" sm="7">
        <v-row justify="center" align="center">
          <!-- opção 1 -->
          <v-col cols="5" class="pa-2 mb-n13" justify="center" align="center">
            <v-card width="100%" height="7em" class="white pt-2"  elevation="0" style="border-radius: 15px; filter: drop-shadow(4px 4px 4px rgba(52, 60, 89, 0.2)); z-index: 1;">
                <p class="font-weight-light text primary--text" style="font-size: 20px;">Autoconfiante</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Independente</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Dominante</p>
            </v-card>
            <v-card @click="p11=clickou(p11)" elevation="0" v-bind:class="{ 'transparent' : p11===0}" height="7em" width="100%"  style="border-radius:15px; background-color:#343C5966; z-index: 2; bottom: 112px; filter: drop-shadow(5px 5px 5px rgba(52, 60, 89, 0.8));">
              <v-card v-if="p11!==0" height="60px" width="60px" color="#FFFFFF80" elevation="0" style="border-radius:40px; top: 28px;">
                <p class=" text-h3 white--text">{{5-p11}}</p>
              </v-card>
            </v-card>
          </v-col>
          <!-- opção 2 -->
          <v-col cols="5" class="pa-2 mb-n13" justify="center" align="center">
            <v-card width="100%" height="7em" class="white pt-2" elevation="0" style="border-radius:15px; filter: drop-shadow(4px 4px 4px rgba(52, 60, 89, 0.2)); z-index: 1;">
                <p class="font-weight-light text primary--text" style="font-size: 20px;">Comunicativo</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Alegre</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Extrovertido</p>
            </v-card>
            <v-card @click="p12=clickou(p12)" elevation="0" v-bind:class="{ 'transparent' : p12===0}" height="7em" width="100%"  style="border-radius:15px; background-color:#343C5966; z-index: 2; bottom: 112px; filter: drop-shadow(5px 5px 5px rgba(52, 60, 89, 0.8));">
              <v-card v-if="p12!==0" height="60px" width="60px" color="#FFFFFF80" elevation="0" style="border-radius:40px; top: 28px;">
                <p class=" text-h3 white--text">{{5-p12}}</p>
              </v-card>
            </v-card>
          </v-col>
          <!-- opção 3 -->
          <v-col cols="5" class="pa-2 mt-n15" justify="center" align="center">
            <v-card width="100%" height="7em" class="white pt-2" elevation="0" style="border-radius:15px; filter: drop-shadow(4px 4px 4px rgba(52, 60, 89, 0.2)); z-index: 1;">
                <p class="font-weight-light text primary--text" style="font-size: 20px;">Acolhedor</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Amigavel</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Paciente</p>
            </v-card>
            <v-card @click="p13=clickou(p13)" elevation="0" v-bind:class="{ 'transparent' : p13===0}" height="7em" width="100%"  style="border-radius:15px; background-color:#343C5966; z-index: 2; bottom: 112px; filter: drop-shadow(5px 5px 5px rgba(52, 60, 89, 0.8));">
              <v-card v-if="p13!==0" height="60px" width="60px" color="#FFFFFF80" elevation="0" style="border-radius:40px; top: 28px;">
                <p class=" text-h3 white--text">{{5-p13}}</p>
              </v-card>
            </v-card>
          </v-col>
          <!-- opção 4 -->
          <v-col cols="5" class="pa-2 mt-n15" justify="center" align="center">
            <v-card width="100%" height="7em" class="white pt-2" elevation="0" style="border-radius:15px; filter: drop-shadow(4px 4px 4px rgba(52, 60, 89, 0.2)); z-index: 1;">
                <p class="font-weight-light text primary--text" style="font-size: 18px;">Autodisciplinado</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 18px;">Atento a detalhes</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 18px;">Diligente</p>
            </v-card>
            <v-card @click="p14=clickou(p14)" elevation="0" v-bind:class="{ 'transparent' : p14===0}" height="7em" width="100%"  style="border-radius:15px; background-color:#343C5966; z-index: 2; bottom: 112px; filter: drop-shadow(5px 5px 5px rgba(52, 60, 89, 0.8));">
              <v-card v-if="p14!==0" height="60px" width="60px" color="#FFFFFF80" elevation="0" style="border-radius:40px; top: 28px; ">
                <p class=" text-h3 white--text">{{5-p14}}</p>
              </v-card>
            </v-card>
          </v-col>
        </v-row>
        <v-row justify="center" align="center" class="mt-n15">
          <v-col v-if="pageNextAlert===1" cols="10" justify="center" align="center" class="mb-n6">
            <p class="font-weight-light text-subtitle-1 error--text mt-n15">*Você tem que marcar todas opções!</p>
          </v-col>
          <v-btn @click="p11=0, p12=0, p13=0, p14=0, clicks=0" style="filter: drop-shadow(0px 0px 5px rgba(232, 93, 117, 0.7));" elevation="0" width="auto" height="50px" class="mr-5 error mt-n5 font-weight-light white--text text-h6 text-capitalize" rounded>Limpar</v-btn>
          <v-btn @click="pageNext()" style="filter: drop-shadow(0px 0px 5px rgba(52, 60, 89, 0.7));" elevation="0" width="auto" height="50px" class="primary mt-n5 font-weight-light white--text text-h6 text-capitalize" rounded>Continuar</v-btn>
        </v-row>
      </v-col>
        <!-- 1 fim -->

       <!-- 2 inicio-->
      <v-col v-if="pageCounter===1" cols="12" xl="3" lg="4" md="5" sm="7">
        <v-row justify="center" align="center">
          <!-- opção 1 -->
          <v-col cols="5" class="pa-2 mb-n13" justify="center" align="center">
            <v-card width="100%" height="7em" class="white pt-2"  elevation="0" style="border-radius: 15px; filter: drop-shadow(4px 4px 4px rgba(52, 60, 89, 0.2)); z-index: 1;">
                <p class="font-weight-light text primary--text" style="font-size: 20px;">Pró-ativo</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Empreendedor</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Corajoso</p>
            </v-card>
            <v-card @click="p21=clickou(p21)" elevation="0" v-bind:class="{ 'transparent' : p21===0}" height="7em" width="100%"  style="border-radius:15px; background-color:#343C5966; z-index: 2; bottom: 112px; filter: drop-shadow(5px 5px 5px rgba(52, 60, 89, 0.8));">
              <v-card v-if="p21!==0" height="60px" width="60px" color="#FFFFFF80" elevation="0" style="border-radius:40px; top: 28px;">
                <p class=" text-h3 white--text">{{5-p21}}</p>
              </v-card>
            </v-card>
          </v-col>
          <!-- opção 2 -->
          <v-col cols="5" class="pa-2 mb-n13" justify="center" align="center">
            <v-card width="100%" height="7em" class="white pt-2" elevation="0" style="border-radius:15px; filter: drop-shadow(4px 4px 4px rgba(52, 60, 89, 0.2)); z-index: 1;">
                <p class="font-weight-light text primary--text" style="font-size: 20px;">Participativo</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Relacional</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Flexível</p>
            </v-card>
            <v-card @click="p22=clickou(p22)" elevation="0" v-bind:class="{ 'transparent' : p22===0}" height="7em" width="100%"  style="border-radius:15px; background-color:#343C5966; z-index: 2; bottom: 112px; filter: drop-shadow(5px 5px 5px rgba(52, 60, 89, 0.8));">
              <v-card v-if="p22!==0" height="60px" width="60px" color="#FFFFFF80" elevation="0" style="border-radius:40px; top: 28px;">
                <p class=" text-h3 white--text">{{5-p22}}</p>
              </v-card>
            </v-card>
          </v-col>
          <!-- opção 4 -->
          <v-col cols="5" class="pa-2 mt-n15" justify="center" align="center">
            <v-card width="100%" height="7em" class="white pt-2" elevation="0" style="border-radius:15px; filter: drop-shadow(4px 4px 4px rgba(52, 60, 89, 0.2)); z-index: 1;">
                <p class="font-weight-light text primary--text" style="font-size: 20px;">Criterioso</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Cuidadoso</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Especialista</p>
            </v-card>
            <v-card @click="p24=clickou(p24)" elevation="0" v-bind:class="{ 'transparent' : p24===0}" height="7em" width="100%"  style="border-radius:15px; background-color:#343C5966; z-index: 2; bottom: 112px; filter: drop-shadow(5px 5px 5px rgba(52, 60, 89, 0.8));">
              <v-card v-if="p24!==0" height="60px" width="60px" color="#FFFFFF80" elevation="0" style="border-radius:40px; top: 28px; ">
                <p class=" text-h3 white--text">{{5-p24}}</p>
              </v-card>
            </v-card>
          </v-col>
          <!-- opção 3 -->
          <v-col cols="5" class="pa-2 mt-n15" justify="center" align="center">
            <v-card width="100%" height="7em" class="white pt-2" elevation="0" style="border-radius:15px; filter: drop-shadow(4px 4px 4px rgba(52, 60, 89, 0.2)); z-index: 1;">
                <p class="font-weight-light text primary--text" style="font-size: 20px;">Agradável</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Tranquilo</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Organizado</p>
            </v-card>
            <v-card @click="p23=clickou(p23)" elevation="0" v-bind:class="{ 'transparent' : p23===0}" height="7em" width="100%"  style="border-radius:15px; background-color:#343C5966; z-index: 2; bottom: 112px; filter: drop-shadow(5px 5px 5px rgba(52, 60, 89, 0.8));">
              <v-card v-if="p23!==0" height="60px" width="60px" color="#FFFFFF80" elevation="0" style="border-radius:40px; top: 28px;">
                <p class=" text-h3 white--text">{{5-p23}}</p>
              </v-card>
            </v-card>
          </v-col>
        </v-row>
        <v-row justify="center" align="center" class="mt-n15">
           <v-col v-if="pageNextAlert===1" cols="12" justify="center" align="center" class="mb-n6">
            <p class="font-weight-light text-subtitle-1 error--text mt-n15">*Você tem que marcar todas opções!</p>
          </v-col>
          <v-btn @click="p21=0, p22=0, p23=0, p24=0, clicks=0" style="filter: drop-shadow(0px 0px 5px rgba(232, 93, 117, 0.7));" elevation="0" width="auto" height="50px" class=" error mt-n5 font-weight-light white--text text-h6 text-capitalize" rounded>Limpar</v-btn>
          <v-btn @click="p21=0, p22=0, p23=0, p24=0, p11=0, p12=0, p13=0, p14=0,clicks=0, pageCounter=pageCounter-1, progress=(pageCounter/11)*100, pageNextAlert=0;" style="filter: drop-shadow(0px 0px 5px rgba(52, 60, 89, 0.25));" elevation="0" width="auto" height="50px" class="mx-2 white mt-n5 font-weight-light primary--text text-h6 text-capitalize" rounded>Voltar</v-btn>
          <v-btn @click="pageNext()" style="filter: drop-shadow(0px 0px 5px rgba(52, 60, 89, 0.7));" elevation="0" width="auto" height="50px" class="primary mt-n5 font-weight-light white--text text-h6 text-capitalize" rounded>Continuar</v-btn>
        </v-row>
      </v-col>
        <!-- 2 fim -->
    <!-- 3 inicio-->
      <v-col v-if="pageCounter===2" cols="12" xl="3" lg="4" md="5" sm="7">
        <v-row justify="center" align="center">
          <!-- opção 2 -->
          <v-col cols="5" class="pa-2 mb-n13" justify="center" align="center">
            <v-card width="100%" height="7em" class="white pt-2" elevation="0" style="border-radius:15px; filter: drop-shadow(4px 4px 4px rgba(52, 60, 89, 0.2)); z-index: 1;">
                <p class="font-weight-light text primary--text" style="font-size: 20px;">Persuasivo</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Contagiante</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Estimulador</p>
            </v-card>
            <v-card @click="p32=clickou(p32)" elevation="0" v-bind:class="{ 'transparent' : p32===0}" height="7em" width="100%"  style="border-radius:15px; background-color:#343C5966; z-index: 2; bottom: 112px; filter: drop-shadow(5px 5px 5px rgba(52, 60, 89, 0.8));">
              <v-card v-if="p32!==0" height="60px" width="60px" color="#FFFFFF80" elevation="0" style="border-radius:40px; top: 28px;">
                <p class=" text-h3 white--text">{{5-p32}}</p>
              </v-card>
            </v-card>
          </v-col>
          <!-- opção 1 -->
          <v-col cols="5" class="pa-2 mb-n13" justify="center" align="center">
            <v-card width="100%" height="7em" class="white pt-2"  elevation="0" style="border-radius: 15px; filter: drop-shadow(4px 4px 4px rgba(52, 60, 89, 0.2)); z-index: 1;">
                <p class="font-weight-light text primary--text" style="font-size: 20px;">Prático</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Rápido</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Eficiente</p>
            </v-card>
            <v-card @click="p31=clickou(p31)" elevation="0" v-bind:class="{ 'transparent' : p31===0}" height="7em" width="100%"  style="border-radius:15px; background-color:#343C5966; z-index: 2; bottom: 112px; filter: drop-shadow(5px 5px 5px rgba(52, 60, 89, 0.8));">
              <v-card v-if="p31!==0" height="60px" width="60px" color="#FFFFFF80" elevation="0" style="border-radius:40px; top: 28px;">
                <p class=" text-h3 white--text">{{5-p31}}</p>
              </v-card>
            </v-card>
          </v-col>
          <!-- opção 3 -->
          <v-col cols="5" class="pa-2 mt-n15" justify="center" align="center">
            <v-card width="100%" height="7em" class="white pt-2" elevation="0" style="border-radius:15px; filter: drop-shadow(4px 4px 4px rgba(52, 60, 89, 0.2)); z-index: 1;">
                <p class="font-weight-light text primary--text" style="font-size: 20px;">Calmo</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Rotineiro</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Constante</p>
            </v-card>
            <v-card @click="p33=clickou(p33)" elevation="0" v-bind:class="{ 'transparent' : p33===0}" height="7em" width="100%"  style="border-radius:15px; background-color:#343C5966; z-index: 2; bottom: 112px; filter: drop-shadow(5px 5px 5px rgba(52, 60, 89, 0.8));">
              <v-card v-if="p33!==0" height="60px" width="60px" color="#FFFFFF80" elevation="0" style="border-radius:40px; top: 28px;">
                <p class=" text-h3 white--text">{{5-p33}}</p>
              </v-card>
            </v-card>
          </v-col>
          <!-- opção 4 -->
          <v-col cols="5" class="pa-2 mt-n15" justify="center" align="center">
            <v-card width="100%" height="7em" class="white pt-2" elevation="0" style="border-radius:15px; filter: drop-shadow(4px 4px 4px rgba(52, 60, 89, 0.2)); z-index: 1;">
                <p class="font-weight-light text primary--text" style="font-size: 20px;">Idealizador</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Perfeccionista</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Uniforme</p>
            </v-card>
            <v-card @click="p34=clickou(p34)" elevation="0" v-bind:class="{ 'transparent' : p34===0}" height="7em" width="100%"  style="border-radius:15px; background-color:#343C5966; z-index: 2; bottom: 112px; filter: drop-shadow(5px 5px 5px rgba(52, 60, 89, 0.8));">
              <v-card v-if="p34!==0" height="60px" width="60px" color="#FFFFFF80" elevation="0" style="border-radius:40px; top: 28px; ">
                <p class=" text-h3 white--text">{{5-p34}}</p>
              </v-card>
            </v-card>
          </v-col>
        </v-row>
        <v-row justify="center" align="center" class="mt-n15">
           <v-col v-if="pageNextAlert===1" cols="12" justify="center" align="center" class="mb-n6">
            <p class="font-weight-light text-subtitle-1 error--text mt-n15">*Você tem que marcar todas opções!</p>
          </v-col>
          <v-btn @click="p31=0, p32=0, p33=0, p34=0, clicks=0" style="filter: drop-shadow(0px 0px 5px rgba(232, 93, 117, 0.7));" elevation="0" width="auto" height="50px" class=" error mt-n5 font-weight-light white--text text-h6 text-capitalize" rounded>Limpar</v-btn>
          <v-btn @click="p31=0, p32=0, p33=0, p34=0, p21=0, p22=0, p23=0, p24=0,clicks=0, pageCounter=pageCounter-1, progress=(pageCounter/11)*100, pageNextAlert=0;" style="filter: drop-shadow(0px 0px 5px rgba(52, 60, 89, 0.25));" elevation="0" width="auto" height="50px" class="mx-2 white mt-n5 font-weight-light primary--text text-h6 text-capitalize" rounded>Voltar</v-btn>
          <v-btn @click="pageNext()" style="filter: drop-shadow(0px 0px 5px rgba(52, 60, 89, 0.7));" elevation="0" width="auto" height="50px" class="primary mt-n5 font-weight-light white--text text-h6 text-capitalize" rounded>Continuar</v-btn>
        </v-row>
      </v-col>
        <!-- 3 fim -->
        <!-- 4 inicio-->
      <v-col v-if="pageCounter===3" cols="12" xl="3" lg="4" md="5" sm="7">
        <v-row justify="center" align="center">
          <!-- opção 2 -->
          <v-col cols="5" class="pa-2 mb-n13" justify="center" align="center">
            <v-card width="100%" height="7em" class="white pt-2" elevation="0" style="border-radius:15px; filter: drop-shadow(4px 4px 4px rgba(52, 60, 89, 0.2)); z-index: 1;">
                <p class="font-weight-light text primary--text" style="font-size: 20px;">Preza pelo</p>
                <p class="font-weight-light text primary--text mt-n7" style="font-size: 20px;">prazer</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Emotivo</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Divertido</p>
            </v-card>
            <v-card @click="p42=clickou(p42)" elevation="0" v-bind:class="{ 'transparent' : p42===0}" height="7em" width="100%"  style="border-radius:15px; background-color:#343C5966; z-index: 2; bottom: 112px; filter: drop-shadow(5px 5px 5px rgba(52, 60, 89, 0.8));">
              <v-card v-if="p42!==0" height="60px" width="60px" color="#FFFFFF80" elevation="0" style="border-radius:40px; top: 28px;">
                <p class=" text-h3 white--text">{{5-p42}}</p>
              </v-card>
            </v-card>
          </v-col>
          <!-- opção 1 -->
          <v-col cols="5" class="pa-2 mb-n13" justify="center" align="center">
            <v-card width="100%" height="7em" class="white pt-2"  elevation="0" style="border-radius: 15px; filter: drop-shadow(4px 4px 4px rgba(52, 60, 89, 0.2)); z-index: 1;">
                <p class="font-weight-light text primary--text" style="font-size: 20px;">Objetivo</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Assertivo</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Focado em</p>
                <p class="font-weight-light text primary--text mt-n7" style="font-size: 20px;">resultados</p>
            </v-card>
            <v-card @click="p41=clickou(p41)" elevation="0" v-bind:class="{ 'transparent' : p41===0}" height="7em" width="100%"  style="border-radius:15px; background-color:#343C5966; z-index: 2; bottom: 112px; filter: drop-shadow(5px 5px 5px rgba(52, 60, 89, 0.8));">
              <v-card v-if="p41!==0" height="60px" width="60px" color="#FFFFFF80" elevation="0" style="border-radius:40px; top: 28px;">
                <p class=" text-h3 white--text">{{5-p41}}</p>
              </v-card>
            </v-card>
          </v-col>
          <!-- opção 4 -->
          <v-col cols="5" class="pa-2 mt-n15" justify="center" align="center">
            <v-card width="100%" height="7em" class="white pt-2" elevation="0" style="border-radius:15px; filter: drop-shadow(4px 4px 4px rgba(52, 60, 89, 0.2)); z-index: 1;">
                <p class="font-weight-light text primary--text" style="font-size: 20px;">Conforme</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Sistemático</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Sensato</p>
            </v-card>
            <v-card @click="p44=clickou(p44)" elevation="0" v-bind:class="{ 'transparent' : p44===0}" height="7em" width="100%"  style="border-radius:15px; background-color:#343C5966; z-index: 2; bottom: 112px; filter: drop-shadow(5px 5px 5px rgba(52, 60, 89, 0.8));">
              <v-card v-if="p44!==0" height="60px" width="60px" color="#FFFFFF80" elevation="0" style="border-radius:40px; top: 28px; ">
                <p class=" text-h3 white--text">{{5-p44}}</p>
              </v-card>
            </v-card>
          </v-col>
          <!-- opção 3 -->
          <v-col cols="5" class="pa-2 mt-n15" justify="center" align="center">
            <v-card width="100%" height="7em" class="white pt-2" elevation="0" style="border-radius:15px; filter: drop-shadow(4px 4px 4px rgba(52, 60, 89, 0.2)); z-index: 1;">
                <p class="font-weight-light text primary--text" style="font-size: 20px;">Conciliador</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Conselheiro</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Bom ouvinte</p>
            </v-card>
            <v-card @click="p43=clickou(p43)" elevation="0" v-bind:class="{ 'transparent' : p43===0}" height="7em" width="100%"  style="border-radius:15px; background-color:#343C5966; z-index: 2; bottom: 112px; filter: drop-shadow(5px 5px 5px rgba(52, 60, 89, 0.8));">
              <v-card v-if="p43!==0" height="60px" width="60px" color="#FFFFFF80" elevation="0" style="border-radius:40px; top: 28px;">
                <p class=" text-h3 white--text">{{5-p43}}</p>
              </v-card>
            </v-card>
          </v-col>
        </v-row>
        <v-row justify="center" align="center" class="mt-n15">
           <v-col v-if="pageNextAlert===1" cols="12" justify="center" align="center" class="mb-n6">
            <p class="font-weight-light text-subtitle-1 error--text mt-n15">*Você tem que marcar todas opções!</p>
          </v-col>
          <v-btn @click="p41=0, p42=0, p43=0, p44=0, clicks=0" style="filter: drop-shadow(0px 0px 5px rgba(232, 93, 117, 0.7));" elevation="0" width="auto" height="50px" class=" error mt-n5 font-weight-light white--text text-h6 text-capitalize" rounded>Limpar</v-btn>
          <v-btn @click="p41=0, p42=0, p43=0, p44=0, p31=0, p32=0, p33=0, p34=0,clicks=0, pageCounter=pageCounter-1, progress=(pageCounter/11)*100, pageNextAlert=0;" style="filter: drop-shadow(0px 0px 5px rgba(52, 60, 89, 0.25));" elevation="0" width="auto" height="50px" class="mx-2 white mt-n5 font-weight-light primary--text text-h6 text-capitalize" rounded>Voltar</v-btn>
          <v-btn @click="pageNext()" style="filter: drop-shadow(0px 0px 5px rgba(52, 60, 89, 0.7));" elevation="0" width="auto" height="50px" class="primary mt-n5 font-weight-light white--text text-h6 text-capitalize" rounded>Continuar</v-btn>
        </v-row>
      </v-col>
        <!-- 4 fim -->
        <!-- 5 inicio-->
      <v-col v-if="pageCounter===4" cols="12" xl="3" lg="4" md="5" sm="7">
        <v-row justify="center" align="center">
          <!-- opção 1 -->
          <v-col cols="5" class="pa-2 mb-n13" justify="center" align="center">
            <v-card width="100%" height="7em" class="white pt-2"  elevation="0" style="border-radius: 15px; filter: drop-shadow(4px 4px 4px rgba(52, 60, 89, 0.2)); z-index: 1;">
                <p class="font-weight-light text primary--text" style="font-size: 20px;">Determinado</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Firme</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Enérgico</p>
            </v-card>
            <v-card @click="p51=clickou(p51)" elevation="0" v-bind:class="{ 'transparent' : p51===0}" height="7em" width="100%"  style="border-radius:15px; background-color:#343C5966; z-index: 2; bottom: 112px; filter: drop-shadow(5px 5px 5px rgba(52, 60, 89, 0.8));">
              <v-card v-if="p51!==0" height="60px" width="60px" color="#FFFFFF80" elevation="0" style="border-radius:40px; top: 28px;">
                <p class=" text-h3 white--text">{{5-p51}}</p>
              </v-card>
            </v-card>
          </v-col>
          <!-- opção 2 -->
          <v-col cols="5" class="pa-2 mb-n13" justify="center" align="center">
            <v-card width="100%" height="7em" class="white pt-2" elevation="0" style="border-radius:15px; filter: drop-shadow(4px 4px 4px rgba(52, 60, 89, 0.2)); z-index: 1;">
                <p class="font-weight-light text primary--text" style="font-size: 20px;">Criativo</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Falante</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Distraído</p>
            </v-card>
            <v-card @click="p52=clickou(p52)" elevation="0" v-bind:class="{ 'transparent' : p52===0}" height="7em" width="100%"  style="border-radius:15px; background-color:#343C5966; z-index: 2; bottom: 112px; filter: drop-shadow(5px 5px 5px rgba(52, 60, 89, 0.8));">
              <v-card v-if="p52!==0" height="60px" width="60px" color="#FFFFFF80" elevation="0" style="border-radius:40px; top: 28px;">
                <p class=" text-h3 white--text">{{5-p52}}</p>
              </v-card>
            </v-card>
          </v-col>
          <!-- opção 3 -->
          <v-col cols="5" class="pa-2 mt-n15" justify="center" align="center">
            <v-card width="100%" height="7em" class="white pt-2" elevation="0" style="border-radius:15px; filter: drop-shadow(4px 4px 4px rgba(52, 60, 89, 0.2)); z-index: 1;">
                <p class="font-weight-light text primary--text" style="font-size: 20px;">Comedido</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Amável</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Responsável</p>
            </v-card>
            <v-card @click="p53=clickou(p53)" elevation="0" v-bind:class="{ 'transparent' : p53===0}" height="7em" width="100%"  style="border-radius:15px; background-color:#343C5966; z-index: 2; bottom: 112px; filter: drop-shadow(5px 5px 5px rgba(52, 60, 89, 0.8));">
              <v-card v-if="p53!==0" height="60px" width="60px" color="#FFFFFF80" elevation="0" style="border-radius:40px; top: 28px;">
                <p class=" text-h3 white--text">{{5-p53}}</p>
              </v-card>
            </v-card>
          </v-col>
          <!-- opção 4 -->
          <v-col cols="5" class="pa-2 mt-n15" justify="center" align="center">
            <v-card width="100%" height="7em" class="white pt-2" elevation="0" style="border-radius:15px; filter: drop-shadow(4px 4px 4px rgba(52, 60, 89, 0.2)); z-index: 1;">
                <p class="font-weight-light text primary--text" style="font-size: 20px;">Preciso</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Lógico</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Racional</p>
            </v-card>
            <v-card @click="p54=clickou(p54)" elevation="0" v-bind:class="{ 'transparent' : p54===0}" height="7em" width="100%"  style="border-radius:15px; background-color:#343C5966; z-index: 2; bottom: 112px; filter: drop-shadow(5px 5px 5px rgba(52, 60, 89, 0.8));">
              <v-card v-if="p54!==0" height="60px" width="60px" color="#FFFFFF80" elevation="0" style="border-radius:40px; top: 28px; ">
                <p class=" text-h3 white--text">{{5-p54}}</p>
              </v-card>
            </v-card>
          </v-col>
        </v-row>
        <v-row justify="center" align="center" class="mt-n15">
           <v-col v-if="pageNextAlert===1" cols="12" justify="center" align="center" class="mb-n6">
            <p class="font-weight-light text-subtitle-1 error--text mt-n15">*Você tem que marcar todas opções!</p>
          </v-col>
          <v-btn @click="p51=0, p52=0, p53=0, p54=0, clicks=0" style="filter: drop-shadow(0px 0px 5px rgba(232, 93, 117, 0.7));" elevation="0" width="auto" height="50px" class=" error mt-n5 font-weight-light white--text text-h6 text-capitalize" rounded>Limpar</v-btn>
          <v-btn @click="p51=0, p52=0, p53=0, p54=0, p41=0, p42=0, p43=0, p44=0,clicks=0, pageCounter=pageCounter-1, progress=(pageCounter/11)*100, pageNextAlert=0;" style="filter: drop-shadow(0px 0px 5px rgba(52, 60, 89, 0.25));" elevation="0" width="auto" height="50px" class="mx-2 white mt-n5 font-weight-light primary--text text-h6 text-capitalize" rounded>Voltar</v-btn>
          <v-btn @click="pageNext()" style="filter: drop-shadow(0px 0px 5px rgba(52, 60, 89, 0.7));" elevation="0" width="auto" height="50px" class="primary mt-n5 font-weight-light white--text text-h6 text-capitalize" rounded>Continuar</v-btn>
        </v-row>
      </v-col>
        <!-- 5 fim -->
    <!-- 6 inicio-->
      <v-col v-if="pageCounter===5" cols="12" xl="3" lg="4" md="5" sm="7">
        <v-row justify="center" align="center">
          <!-- opção 2 -->
          <v-col cols="5" class="pa-2 mb-n13" justify="center" align="center">
            <v-card width="100%" height="7em" class="white pt-2" elevation="0" style="border-radius:15px; filter: drop-shadow(4px 4px 4px rgba(52, 60, 89, 0.2)); z-index: 1;">
                <p class="font-weight-light text primary--text" style="font-size: 20px;">Participativo</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Facilitador</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Influenciador</p>
            </v-card>
            <v-card @click="p62=clickou(p62)" elevation="0" v-bind:class="{ 'transparent' : p62===0}" height="7em" width="100%"  style="border-radius:15px; background-color:#343C5966; z-index: 2; bottom: 112px; filter: drop-shadow(5px 5px 5px rgba(52, 60, 89, 0.8));">
              <v-card v-if="p62!==0" height="60px" width="60px" color="#FFFFFF80" elevation="0" style="border-radius:40px; top: 28px;">
                <p class=" text-h3 white--text">{{5-p62}}</p>
              </v-card>
            </v-card>
          </v-col>
          <!-- opção 1 -->
          <v-col cols="5" class="pa-2 mb-n13" justify="center" align="center">
            <v-card width="100%" height="7em" class="white pt-2"  elevation="0" style="border-radius: 15px; filter: drop-shadow(4px 4px 4px rgba(52, 60, 89, 0.2)); z-index: 1;">
                <p class="font-weight-light text primary--text" style="font-size: 20px;">Lutador</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Combativo</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Agressivo</p>
            </v-card>
            <v-card @click="p61=clickou(p61)" elevation="0" v-bind:class="{ 'transparent' : p61===0}" height="7em" width="100%"  style="border-radius:15px; background-color:#343C5966; z-index: 2; bottom: 112px; filter: drop-shadow(5px 5px 5px rgba(52, 60, 89, 0.8));">
              <v-card v-if="p61!==0" height="60px" width="60px" color="#FFFFFF80" elevation="0" style="border-radius:40px; top: 28px;">
                <p class=" text-h3 white--text">{{5-p61}}</p>
              </v-card>
            </v-card>
          </v-col>
          <!-- opção 3 -->
          <v-col cols="5" class="pa-2 mt-n15" justify="center" align="center">
            <v-card width="100%" height="7em" class="white pt-2" elevation="0" style="border-radius:15px; filter: drop-shadow(4px 4px 4px rgba(52, 60, 89, 0.2)); z-index: 1;">
                <p class="font-weight-light text primary--text" style="font-size: 20px;">Auto-controlado</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Conservador</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Responsável</p>
            </v-card>
            <v-card @click="p63=clickou(p63)" elevation="0" v-bind:class="{ 'transparent' : p63===0}" height="7em" width="100%"  style="border-radius:15px; background-color:#343C5966; z-index: 2; bottom: 112px; filter: drop-shadow(5px 5px 5px rgba(52, 60, 89, 0.8));">
              <v-card v-if="p63!==0" height="60px" width="60px" color="#FFFFFF80" elevation="0" style="border-radius:40px; top: 28px;">
                <p class=" text-h3 white--text">{{5-p63}}</p>
              </v-card>
            </v-card>
          </v-col>
          <!-- opção 4 -->
          <v-col cols="5" class="pa-2 mt-n15" justify="center" align="center">
            <v-card width="100%" height="7em" class="white pt-2" elevation="0" style="border-radius:15px; filter: drop-shadow(4px 4px 4px rgba(52, 60, 89, 0.2)); z-index: 1;">
                <p class="font-weight-light text primary--text" style="font-size: 20px;">Profundo</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Perceptivo</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Estrátegico</p>
            </v-card>
            <v-card @click="p64=clickou(p64)" elevation="0" v-bind:class="{ 'transparent' : p64===0}" height="7em" width="100%"  style="border-radius:15px; background-color:#343C5966; z-index: 2; bottom: 112px; filter: drop-shadow(5px 5px 5px rgba(52, 60, 89, 0.8));">
              <v-card v-if="p64!==0" height="60px" width="60px" color="#FFFFFF80" elevation="0" style="border-radius:40px; top: 28px; ">
                <p class=" text-h3 white--text">{{5-p64}}</p>
              </v-card>
            </v-card>
          </v-col>
        </v-row>
        <v-row justify="center" align="center" class="mt-n15">
           <v-col v-if="pageNextAlert===1" cols="12" justify="center" align="center" class="mb-n6">
            <p class="font-weight-light text-subtitle-1 error--text mt-n15">*Você tem que marcar todas opções!</p>
          </v-col>
          <v-btn @click="p61=0, p62=0, p63=0, p64=0, clicks=0" style="filter: drop-shadow(0px 0px 5px rgba(232, 93, 117, 0.7));" elevation="0" width="auto" height="50px" class=" error mt-n5 font-weight-light white--text text-h6 text-capitalize" rounded>Limpar</v-btn>
          <v-btn @click="p61=0, p62=0, p63=0, p64=0, p51=0, p52=0, p53=0, p54=0,clicks=0, pageCounter=pageCounter-1, progress=(pageCounter/11)*100, pageNextAlert=0;" style="filter: drop-shadow(0px 0px 5px rgba(52, 60, 89, 0.25));" elevation="0" width="auto" height="50px" class="mx-2 white mt-n5 font-weight-light primary--text text-h6 text-capitalize" rounded>Voltar</v-btn>
          <v-btn @click="pageNext()" style="filter: drop-shadow(0px 0px 5px rgba(52, 60, 89, 0.7));" elevation="0" width="auto" height="50px" class="primary mt-n5 font-weight-light white--text text-h6 text-capitalize" rounded>Continuar</v-btn>
        </v-row>
      </v-col>
        <!-- 6 fim -->
    <!-- 7 inicio-->
      <v-col v-if="pageCounter===6" cols="12" xl="3" lg="4" md="5" sm="7">
        <v-row justify="center" align="center">
          <!-- opção 2 -->
          <v-col cols="5" class="pa-2 mb-n13" justify="center" align="center">
            <v-card width="100%" height="7em" class="white pt-2" elevation="0" style="border-radius:15px; filter: drop-shadow(4px 4px 4px rgba(52, 60, 89, 0.2)); z-index: 1;">
                <p class="font-weight-light text primary--text" style="font-size: 20px;">Articulador</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Empolgante</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Motivador</p>
            </v-card>
            <v-card @click="p72=clickou(p72)" elevation="0" v-bind:class="{ 'transparent' : p72===0}" height="7em" width="100%"  style="border-radius:15px; background-color:#343C5966; z-index: 2; bottom: 112px; filter: drop-shadow(5px 5px 5px rgba(52, 60, 89, 0.8));">
              <v-card v-if="p72!==0" height="60px" width="60px" color="#FFFFFF80" elevation="0" style="border-radius:40px; top: 28px;">
                <p class=" text-h3 white--text">{{5-p72}}</p>
              </v-card>
            </v-card>
          </v-col>
          <!-- opção 1 -->
          <v-col cols="5" class="pa-2 mb-n13" justify="center" align="center">
            <v-card width="100%" height="7em" class="white pt-2"  elevation="0" style="border-radius: 15px; filter: drop-shadow(4px 4px 4px rgba(52, 60, 89, 0.2)); z-index: 1;">
                <p class="font-weight-light text primary--text" style="font-size: 20px;">Automotivado</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Pioneiro</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Impulsionador</p>
            </v-card>
            <v-card @click="p71=clickou(p71)" elevation="0" v-bind:class="{ 'transparent' : p71===0}" height="7em" width="100%"  style="border-radius:15px; background-color:#343C5966; z-index: 2; bottom: 112px; filter: drop-shadow(5px 5px 5px rgba(52, 60, 89, 0.8));">
              <v-card v-if="p71!==0" height="60px" width="60px" color="#FFFFFF80" elevation="0" style="border-radius:40px; top: 28px;">
                <p class=" text-h3 white--text">{{5-p71}}</p>
              </v-card>
            </v-card>
          </v-col>
          <!-- opção 4 -->
          <v-col cols="5" class="pa-2 mt-n15" justify="center" align="center">
            <v-card width="100%" height="7em" class="white pt-2" elevation="0" style="border-radius:15px; filter: drop-shadow(4px 4px 4px rgba(52, 60, 89, 0.2)); z-index: 1;">
                <p class="font-weight-light text primary--text" style="font-size: 20px;">Exato</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Exigente</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Estruturado</p>
            </v-card>
            <v-card @click="p74=clickou(p74)" elevation="0" v-bind:class="{ 'transparent' : p74===0}" height="7em" width="100%"  style="border-radius:15px; background-color:#343C5966; z-index: 2; bottom: 112px; filter: drop-shadow(5px 5px 5px rgba(52, 60, 89, 0.8));">
              <v-card v-if="p74!==0" height="60px" width="60px" color="#FFFFFF80" elevation="0" style="border-radius:40px; top: 28px; ">
                <p class=" text-h3 white--text">{{5-p74}}</p>
              </v-card>
            </v-card>
          </v-col>
          <!-- opção 3 -->
          <v-col cols="5" class="pa-2 mt-n15" justify="center" align="center">
            <v-card width="100%" height="7em" class="white pt-2" elevation="0" style="border-radius:15px; filter: drop-shadow(4px 4px 4px rgba(52, 60, 89, 0.2)); z-index: 1;">
                <p class="font-weight-light text primary--text" style="font-size: 20px;">Persistente</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Prevenido</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Tolerante</p>
            </v-card>
            <v-card @click="p73=clickou(p73)" elevation="0" v-bind:class="{ 'transparent' : p73===0}" height="7em" width="100%"  style="border-radius:15px; background-color:#343C5966; z-index: 2; bottom: 112px; filter: drop-shadow(5px 5px 5px rgba(52, 60, 89, 0.8));">
              <v-card v-if="p73!==0" height="60px" width="60px" color="#FFFFFF80" elevation="0" style="border-radius:40px; top: 28px;">
                <p class=" text-h3 white--text">{{5-p73}}</p>
              </v-card>
            </v-card>
          </v-col>
        </v-row>
        <v-row justify="center" align="center" class="mt-n15">
           <v-col v-if="pageNextAlert===1" cols="12" justify="center" align="center" class="mb-n6">
            <p class="font-weight-light text-subtitle-1 error--text mt-n15">*Você tem que marcar todas opções!</p>
          </v-col>
          <v-btn @click="p71=0, p72=0, p73=0, p74=0, clicks=0" style="filter: drop-shadow(0px 0px 5px rgba(232, 93, 117, 0.7));" elevation="0" width="auto" height="50px" class=" error mt-n5 font-weight-light white--text text-h6 text-capitalize" rounded>Limpar</v-btn>
          <v-btn @click="p71=0, p72=0, p73=0, p74=0, p61=0, p62=0, p63=0, p64=0,clicks=0, pageCounter=pageCounter-1, progress=(pageCounter/11)*100, pageNextAlert=0;" style="filter: drop-shadow(0px 0px 5px rgba(52, 60, 89, 0.25));" elevation="0" width="auto" height="50px" class="mx-2 white mt-n5 font-weight-light primary--text text-h6 text-capitalize" rounded>Voltar</v-btn>
          <v-btn @click="pageNext()" style="filter: drop-shadow(0px 0px 5px rgba(52, 60, 89, 0.7));" elevation="0" width="auto" height="50px" class="primary mt-n5 font-weight-light white--text text-h6 text-capitalize" rounded>Continuar</v-btn>
        </v-row>
      </v-col>
        <!-- 7 fim -->
    <!-- 8 inicio-->
      <v-col v-if="pageCounter===7" cols="12" xl="3" lg="4" md="5" sm="7">
        <v-row justify="center" align="center">
          <!-- opção 1 -->
          <v-col cols="5" class="pa-2 mb-n13" justify="center" align="center">
            <v-card width="100%" height="7em" class="white pt-2"  elevation="0" style="border-radius: 15px; filter: drop-shadow(4px 4px 4px rgba(52, 60, 89, 0.2)); z-index: 1;">
                <p class="font-weight-light text primary--text" style="font-size: 20px;">Resolvedor</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Destemido</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Desafiador</p>
            </v-card>
            <v-card @click="p81=clickou(p81)" elevation="0" v-bind:class="{ 'transparent' : p81===0}" height="7em" width="100%"  style="border-radius:15px; background-color:#343C5966; z-index: 2; bottom: 112px; filter: drop-shadow(5px 5px 5px rgba(52, 60, 89, 0.8));">
              <v-card v-if="p81!==0" height="60px" width="60px" color="#FFFFFF80" elevation="0" style="border-radius:40px; top: 28px;">
                <p class=" text-h3 white--text">{{5-p81}}</p>
              </v-card>
            </v-card>
          </v-col>
          <!-- opção 2 -->
          <v-col cols="5" class="pa-2 mb-n13" justify="center" align="center">
            <v-card width="100%" height="7em" class="white pt-2" elevation="0" style="border-radius:15px; filter: drop-shadow(4px 4px 4px rgba(52, 60, 89, 0.2)); z-index: 1;">
                <p class="font-weight-light text primary--text" style="font-size: 20px;">Vaidoso</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Simpático</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Gosta de ser</p>
                <p class="font-weight-light text primary--text mt-n7" style="font-size: 20px;">reconhecido</p>
            </v-card>
            <v-card @click="p82=clickou(p82)" elevation="0" v-bind:class="{ 'transparent' : p82===0}" height="7em" width="100%"  style="border-radius:15px; background-color:#343C5966; z-index: 2; bottom: 112px; filter: drop-shadow(5px 5px 5px rgba(52, 60, 89, 0.8));">
              <v-card v-if="p82!==0" height="60px" width="60px" color="#FFFFFF80" elevation="0" style="border-radius:40px; top: 28px;">
                <p class=" text-h3 white--text">{{5-p82}}</p>
              </v-card>
            </v-card>
          </v-col>
          <!-- opção 4 -->
          <v-col cols="5" class="pa-2 mt-n15" justify="center" align="center">
            <v-card width="100%" height="7em" class="white pt-2" elevation="0" style="border-radius:15px; filter: drop-shadow(4px 4px 4px rgba(52, 60, 89, 0.2)); z-index: 1;">
                <p class="font-weight-light text primary--text" style="font-size: 20px;">Ponderado</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Ordenador</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Analisador</p>
            </v-card>
            <v-card @click="p84=clickou(p84)" elevation="0" v-bind:class="{ 'transparent' : p84===0}" height="7em" width="100%"  style="border-radius:15px; background-color:#343C5966; z-index: 2; bottom: 112px; filter: drop-shadow(5px 5px 5px rgba(52, 60, 89, 0.8));">
              <v-card v-if="p84!==0" height="60px" width="60px" color="#FFFFFF80" elevation="0" style="border-radius:40px; top: 28px; ">
                <p class=" text-h3 white--text">{{5-p84}}</p>
              </v-card>
            </v-card>
          </v-col>
          <!-- opção 3 -->
          <v-col cols="5" class="pa-2 mt-n15" justify="center" align="center">
            <v-card width="100%" height="7em" class="white pt-2" elevation="0" style="border-radius:15px; filter: drop-shadow(4px 4px 4px rgba(52, 60, 89, 0.2)); z-index: 1;">
                <p class="font-weight-light text primary--text" style="font-size: 20px;">Aconselhador</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Harmônico</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Apoiador</p>
            </v-card>
            <v-card @click="p83=clickou(p83)" elevation="0" v-bind:class="{ 'transparent' : p83===0}" height="7em" width="100%"  style="border-radius:15px; background-color:#343C5966; z-index: 2; bottom: 112px; filter: drop-shadow(5px 5px 5px rgba(52, 60, 89, 0.8));">
              <v-card v-if="p83!==0" height="60px" width="60px" color="#FFFFFF80" elevation="0" style="border-radius:40px; top: 28px;">
                <p class=" text-h3 white--text">{{5-p83}}</p>
              </v-card>
            </v-card>
          </v-col>
        </v-row>
        <v-row justify="center" align="center" class="mt-n15">
           <v-col v-if="pageNextAlert===1" cols="12" justify="center" align="center" class="mb-n6">
            <p class="font-weight-light text-subtitle-1 error--text mt-n15">*Você tem que marcar todas opções!</p>
          </v-col>
          <v-btn @click="p81=0, p82=0, p83=0, p84=0, clicks=0" style="filter: drop-shadow(0px 0px 5px rgba(232, 93, 117, 0.7));" elevation="0" width="auto" height="50px" class=" error mt-n5 font-weight-light white--text text-h6 text-capitalize" rounded>Limpar</v-btn>
          <v-btn @click="p81=0, p82=0, p83=0, p84=0, p71=0, p72=0, p73=0, p74=0,clicks=0, pageCounter=pageCounter-1, progress=(pageCounter/11)*100, pageNextAlert=0;" style="filter: drop-shadow(0px 0px 5px rgba(52, 60, 89, 0.25));" elevation="0" width="auto" height="50px" class="mx-2 white mt-n5 font-weight-light primary--text text-h6 text-capitalize" rounded>Voltar</v-btn>
          <v-btn @click="pageNext()" style="filter: drop-shadow(0px 0px 5px rgba(52, 60, 89, 0.7));" elevation="0" width="auto" height="50px" class="primary mt-n5 font-weight-light white--text text-h6 text-capitalize" rounded>Continuar</v-btn>
        </v-row>
      </v-col>
        <!-- 8 fim -->
    <!-- 9 inicio-->
      <v-col v-if="pageCounter===8" cols="12" xl="3" lg="4" md="5" sm="7">
        <v-row justify="center" align="center">
          <!-- opção 1 -->
          <v-col cols="5" class="pa-2 mb-n13" justify="center" align="center">
            <v-card width="100%" height="7em" class="white pt-2"  elevation="0" style="border-radius: 15px; filter: drop-shadow(4px 4px 4px rgba(52, 60, 89, 0.2)); z-index: 1;">
                <p class="font-weight-light text primary--text" style="font-size: 20px;">Competitivo</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Assume riscos</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Desbravador</p>
            </v-card>
            <v-card @click="p91=clickou(p91)" elevation="0" v-bind:class="{ 'transparent' : p91===0}" height="7em" width="100%"  style="border-radius:15px; background-color:#343C5966; z-index: 2; bottom: 112px; filter: drop-shadow(5px 5px 5px rgba(52, 60, 89, 0.8));">
              <v-card v-if="p91!==0" height="60px" width="60px" color="#FFFFFF80" elevation="0" style="border-radius:40px; top: 28px;">
                <p class=" text-h3 white--text">{{5-p91}}</p>
              </v-card>
            </v-card>
          </v-col>
          <!-- opção 2 -->
          <v-col cols="5" class="pa-2 mb-n13" justify="center" align="center">
            <v-card width="100%" height="7em" class="white pt-2" elevation="0" style="border-radius:15px; filter: drop-shadow(4px 4px 4px rgba(52, 60, 89, 0.2)); z-index: 1;">
                <p class="font-weight-light text primary--text" style="font-size: 20px;">Entusiasmado</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Impulsivo</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Otimista</p>
            </v-card>
            <v-card @click="p92=clickou(p92)" elevation="0" v-bind:class="{ 'transparent' : p92===0}" height="7em" width="100%"  style="border-radius:15px; background-color:#343C5966; z-index: 2; bottom: 112px; filter: drop-shadow(5px 5px 5px rgba(52, 60, 89, 0.8));">
              <v-card v-if="p92!==0" height="60px" width="60px" color="#FFFFFF80" elevation="0" style="border-radius:40px; top: 28px;">
                <p class=" text-h3 white--text">{{5-p92}}</p>
              </v-card>
            </v-card>
          </v-col>
          <!-- opção 3 -->
          <v-col cols="5" class="pa-2 mt-n15" justify="center" align="center">
            <v-card width="100%" height="7em" class="white pt-2" elevation="0" style="border-radius:15px; filter: drop-shadow(4px 4px 4px rgba(52, 60, 89, 0.2)); z-index: 1;">
                <p class="font-weight-light text primary--text" style="font-size: 20px;">Moderado</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Equilibrado</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Estável</p>
            </v-card>
            <v-card @click="p93=clickou(p93)" elevation="0" v-bind:class="{ 'transparent' : p93===0}" height="7em" width="100%"  style="border-radius:15px; background-color:#343C5966; z-index: 2; bottom: 112px; filter: drop-shadow(5px 5px 5px rgba(52, 60, 89, 0.8));">
              <v-card v-if="p93!==0" height="60px" width="60px" color="#FFFFFF80" elevation="0" style="border-radius:40px; top: 28px;">
                <p class=" text-h3 white--text">{{5-p93}}</p>
              </v-card>
            </v-card>
          </v-col>
          <!-- opção 4 -->
          <v-col cols="5" class="pa-2 mt-n15" justify="center" align="center">
            <v-card width="100%" height="7em" class="white pt-2" elevation="0" style="border-radius:15px; filter: drop-shadow(4px 4px 4px rgba(52, 60, 89, 0.2)); z-index: 1;">
                <p class="font-weight-light text primary--text" style="font-size: 20px;">Teórico</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Conservador</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Aprofunda</p>
                <p class="font-weight-light text primary--text mt-n6" style="font-size: 20px;">conhecimentos</p>
            </v-card>
            <v-card @click="p94=clickou(p94)" elevation="0" v-bind:class="{ 'transparent' : p94===0}" height="7em" width="100%"  style="border-radius:15px; background-color:#343C5966; z-index: 2; bottom: 112px; filter: drop-shadow(5px 5px 5px rgba(52, 60, 89, 0.8));">
              <v-card v-if="p94!==0" height="60px" width="60px" color="#FFFFFF80" elevation="0" style="border-radius:40px; top: 28px; ">
                <p class=" text-h3 white--text">{{5-p94}}</p>
              </v-card>
            </v-card>
          </v-col>
        </v-row>
        <v-row justify="center" align="center" class="mt-n15">
           <v-col v-if="pageNextAlert===1" cols="12" justify="center" align="center" class="mb-n6">
            <p class="font-weight-light text-subtitle-1 error--text mt-n15">*Você tem que marcar todas opções!</p>
          </v-col>
          <v-btn @click="p91=0, p92=0, p93=0, p94=0, clicks=0" style="filter: drop-shadow(0px 0px 5px rgba(232, 93, 117, 0.7));" elevation="0" width="auto" height="50px" class=" error mt-n5 font-weight-light white--text text-h6 text-capitalize" rounded>Limpar</v-btn>
          <v-btn @click="p91=0, p92=0, p93=0, p94=0, p81=0, p82=0, p83=0, p84=0,clicks=0, pageCounter=pageCounter-1, progress=(pageCounter/11)*100, pageNextAlert=0;" style="filter: drop-shadow(0px 0px 5px rgba(52, 60, 89, 0.25));" elevation="0" width="auto" height="50px" class="mx-2 white mt-n5 font-weight-light primary--text text-h6 text-capitalize" rounded>Voltar</v-btn>
          <v-btn @click="pageNext()" style="filter: drop-shadow(0px 0px 5px rgba(52, 60, 89, 0.7));" elevation="0" width="auto" height="50px" class="primary mt-n5 font-weight-light white--text text-h6 text-capitalize" rounded>Continuar</v-btn>
        </v-row>
      </v-col>
        <!-- 9 fim -->
    <!-- 10 inicio-->
      <v-col v-if="pageCounter===9" cols="12" xl="3" lg="4" md="5" sm="7">
        <v-row justify="center" align="center">
          <!-- opção 2 -->
          <v-col cols="5" class="pa-2 mb-n13" justify="center" align="center">
            <v-card width="100%" height="7em" class="white pt-2" elevation="0" style="border-radius:15px; filter: drop-shadow(4px 4px 4px rgba(52, 60, 89, 0.2)); z-index: 1;">
                <p class="font-weight-light text primary--text" style="font-size: 20px;">Agregador</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Sociável</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Móbil</p>
            </v-card>
            <v-card @click="p102=clickou(p102)" elevation="0" v-bind:class="{ 'transparent' : p102===0}" height="7em" width="100%"  style="border-radius:15px; background-color:#343C5966; z-index: 2; bottom: 112px; filter: drop-shadow(5px 5px 5px rgba(52, 60, 89, 0.8));">
              <v-card v-if="p102!==0" height="60px" width="60px" color="#FFFFFF80" elevation="0" style="border-radius:40px; top: 28px;">
                <p class=" text-h3 white--text">{{5-p102}}</p>
              </v-card>
            </v-card>
          </v-col>
          <!-- opção 1 -->
          <v-col cols="5" class="pa-2 mb-n13" justify="center" align="center">
            <v-card width="100%" height="7em" class="white pt-2"  elevation="0" style="border-radius: 15px; filter: drop-shadow(4px 4px 4px rgba(52, 60, 89, 0.2)); z-index: 1;">
                <p class="font-weight-light text primary--text" style="font-size: 20px;">Direcionador</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Solucionador</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Empreendedor</p>
            </v-card>
            <v-card @click="p101=clickou(p101)" elevation="0" v-bind:class="{ 'transparent' : p101===0}" height="7em" width="100%"  style="border-radius:15px; background-color:#343C5966; z-index: 2; bottom: 112px; filter: drop-shadow(5px 5px 5px rgba(52, 60, 89, 0.8));">
              <v-card v-if="p101!==0" height="60px" width="60px" color="#FFFFFF80" elevation="0" style="border-radius:40px; top: 28px;">
                <p class=" text-h3 white--text">{{5-p101}}</p>
              </v-card>
            </v-card>
          </v-col>
          <!-- opção 4 -->
          <v-col cols="5" class="pa-2 mt-n15" justify="center" align="center">
            <v-card width="100%" height="7em" class="white pt-2" elevation="0" style="border-radius:15px; filter: drop-shadow(4px 4px 4px rgba(52, 60, 89, 0.2)); z-index: 1;">
                <p class="font-weight-light text primary--text" style="font-size: 20px;">Regulador</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Especialista</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Orientador</p>
            </v-card>
            <v-card @click="p104=clickou(p104)" elevation="0" v-bind:class="{ 'transparent' : p104===0}" height="7em" width="100%"  style="border-radius:15px; background-color:#343C5966; z-index: 2; bottom: 112px; filter: drop-shadow(5px 5px 5px rgba(52, 60, 89, 0.8));">
              <v-card v-if="p104!==0" height="60px" width="60px" color="#FFFFFF80" elevation="0" style="border-radius:40px; top: 28px; ">
                <p class=" text-h3 white--text">{{5-p104}}</p>
              </v-card>
            </v-card>
          </v-col>
          <!-- opção 3 -->
          <v-col cols="5" class="pa-2 mt-n15" justify="center" align="center">
            <v-card width="100%" height="7em" class="white pt-2" elevation="0" style="border-radius:15px; filter: drop-shadow(4px 4px 4px rgba(52, 60, 89, 0.2)); z-index: 1;">
                <p class="font-weight-light text primary--text" style="font-size: 20px;">Conciliador</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Observador</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Diplomata</p>
            </v-card>
            <v-card @click="p103=clickou(p103)" elevation="0" v-bind:class="{ 'transparent' : p103===0}" height="7em" width="100%"  style="border-radius:15px; background-color:#343C5966; z-index: 2; bottom: 112px; filter: drop-shadow(5px 5px 5px rgba(52, 60, 89, 0.8));">
              <v-card v-if="p103!==0" height="60px" width="60px" color="#FFFFFF80" elevation="0" style="border-radius:40px; top: 28px;">
                <p class=" text-h3 white--text">{{5-p103}}</p>
              </v-card>
            </v-card>
          </v-col>
        </v-row>
        <v-row justify="center" align="center" class="mt-n15">
           <v-col v-if="pageNextAlert===1" cols="12" justify="center" align="center" class="mb-n6">
            <p class="font-weight-light text-subtitle-1 error--text mt-n15">*Você tem que marcar todas opções!</p>
          </v-col>
          <v-btn @click="p101=0, p102=0, p103=0, p104=0, clicks=0" style="filter: drop-shadow(0px 0px 5px rgba(232, 93, 117, 0.7));" elevation="0" width="auto" height="50px" class=" error mt-n5 font-weight-light white--text text-h6 text-capitalize" rounded>Limpar</v-btn>
          <v-btn @click="p101=0, p102=0, p103=0, p104=0, p91=0, p92=0, p93=0, p94=0,clicks=0, pageCounter=pageCounter-1, progress=(pageCounter/11)*100, pageNextAlert=0;" style="filter: drop-shadow(0px 0px 5px rgba(52, 60, 89, 0.25));" elevation="0" width="auto" height="50px" class="mx-2 white mt-n5 font-weight-light primary--text text-h6 text-capitalize" rounded>Voltar</v-btn>
          <v-btn @click="pageNext" style="filter: drop-shadow(0px 0px 5px rgba(52, 60, 89, 0.7));" elevation="0" width="auto" height="50px" class="primary mt-n5 font-weight-light white--text text-h6 text-capitalize" rounded>Continuar</v-btn>
        </v-row>
      </v-col>
        <!-- 10 fim -->
        <!-- 11 inicio-->
      <v-col v-if="pageCounter===10" cols="12" xl="3" lg="4" md="5" sm="7">
        <v-row justify="center" align="center">
          <!-- opção 1 -->
          <v-col cols="5" class="pa-2 mb-n13" justify="center" align="center">
            <v-card width="100%" height="7em" class="white pt-2"  elevation="0" style="border-radius: 15px; filter: drop-shadow(4px 4px 4px rgba(52, 60, 89, 0.2)); z-index: 1;">
                <p class="font-weight-light text primary--text" style="font-size: 20px;">Extrovertido</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Sociável</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Influente</p>
            </v-card>
            <v-card @click="p111=clickou(p111)" elevation="0" v-bind:class="{ 'transparent' : p111===0}" height="7em" width="100%"  style="border-radius:15px; background-color:#343C5966; z-index: 2; bottom: 112px; filter: drop-shadow(5px 5px 5px rgba(52, 60, 89, 0.8));">
              <v-card v-if="p111!==0" height="60px" width="60px" color="#FFFFFF80" elevation="0" style="border-radius:40px; top: 28px;">
                <p class=" text-h3 white--text">{{5-p111}}</p>
              </v-card>
            </v-card>
          </v-col>
          <!-- opção 2 -->
          <v-col cols="5" class="pa-2 mb-n13" justify="center" align="center">
            <v-card width="100%" height="7em" class="white pt-2" elevation="0" style="border-radius:15px; filter: drop-shadow(4px 4px 4px rgba(52, 60, 89, 0.2)); z-index: 1;">
                <p class="font-weight-light text primary--text" style="font-size: 20px;">Paciente</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Tolerante</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Empático</p>
            </v-card>
            <v-card @click="p112=clickou(p112)" elevation="0" v-bind:class="{ 'transparent' : p112===0}" height="7em" width="100%"  style="border-radius:15px; background-color:#343C5966; z-index: 2; bottom: 112px; filter: drop-shadow(5px 5px 5px rgba(52, 60, 89, 0.8));">
              <v-card v-if="p112!==0" height="60px" width="60px" color="#FFFFFF80" elevation="0" style="border-radius:40px; top: 28px;">
                <p class=" text-h3 white--text">{{5-p112}}</p>
              </v-card>
            </v-card>
          </v-col>
          <!-- opção 3 -->
          <v-col cols="5" class="pa-2 mt-n15" justify="center" align="center">
            <v-card width="100%" height="7em" class="white pt-2" elevation="0" style="border-radius:15px; filter: drop-shadow(4px 4px 4px rgba(52, 60, 89, 0.2)); z-index: 1;">
                <p class="font-weight-light text primary--text" style="font-size: 20px;">Dominante</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Proativo</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Líder</p>
            </v-card>
            <v-card @click="p113=clickou(p113)" elevation="0" v-bind:class="{ 'transparent' : p113===0}" height="7em" width="100%"  style="border-radius:15px; background-color:#343C5966; z-index: 2; bottom: 112px; filter: drop-shadow(5px 5px 5px rgba(52, 60, 89, 0.8));">
              <v-card v-if="p113!==0" height="60px" width="60px" color="#FFFFFF80" elevation="0" style="border-radius:40px; top: 28px;">
                <p class=" text-h3 white--text">{{5-p113}}</p>
              </v-card>
            </v-card>
          </v-col>
          <!-- opção 4 -->
          <v-col cols="5" class="pa-2 mt-n15" justify="center" align="center">
            <v-card width="100%" height="7em" class="white pt-2" elevation="0" style="border-radius:15px; filter: drop-shadow(4px 4px 4px rgba(52, 60, 89, 0.2)); z-index: 1;">
                <p class="font-weight-light text primary--text" style="font-size: 20px;">Organizado</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Detalhista</p>
                <p class="font-weight-light text primary--text mt-n5" style="font-size: 20px;">Rigoroso</p>
            </v-card>
            <v-card @click="p114=clickou(p114)" elevation="0" v-bind:class="{ 'transparent' : p114===0}" height="7em" width="100%"  style="border-radius:15px; background-color:#343C5966; z-index: 2; bottom: 112px; filter: drop-shadow(5px 5px 5px rgba(52, 60, 89, 0.8));">
              <v-card v-if="p114!==0" height="60px" width="60px" color="#FFFFFF80" elevation="0" style="border-radius:40px; top: 28px; ">
                <p class=" text-h3 white--text">{{5-p114}}</p>
              </v-card>
            </v-card>
          </v-col>
        </v-row>
        <v-row justify="center" align="center" class="mt-n15">
           <v-col v-if="pageNextAlert===1" cols="12" justify="center" align="center" class="mb-n6">
            <p class="font-weight-light text-subtitle-1 error--text mt-n15">*Você tem que marcar todas opções!</p>
          </v-col>
          <v-btn @click="p111=0, p112=0, p113=0, p114=0, clicks=0" style="filter: drop-shadow(0px 0px 5px rgba(232, 93, 117, 0.7));" elevation="0" width="auto" height="50px" class=" error mt-n5 font-weight-light white--text text-h6 text-capitalize" rounded>Limpar</v-btn>
          <v-btn @click="p111=0, p112=0, p113=0, p114=0, p101=0, p102=0, p103=0, p104=0,clicks=0, pageCounter=pageCounter-1, progress=(pageCounter/11)*100, pageNextAlert=0;" style="filter: drop-shadow(0px 0px 5px rgba(52, 60, 89, 0.25));" elevation="0" width="auto" height="50px" class="mx-2 white mt-n5 font-weight-light primary--text text-h6 text-capitalize" rounded>Voltar</v-btn>
          <v-btn :loading="loading" @click="getTipos(), pageNext()" style="filter: drop-shadow(0px 0px 5px rgba(52, 60, 89, 0.7));" elevation="0" width="auto" height="50px" class="success mt-n5 font-weight-light white--text text-h6 text-capitalize" rounded>Finalizar</v-btn>
        </v-row>
      </v-col>
        <!-- 11 fim -->
    </v-row>
    <!-- teste fim -->
    <!-- perguntas inicio -->
    <v-row v-if="pageCounter==11" justify="center" align="center">
      <v-col justify="center" align="center" cols="11" md="6" lg="4" xl="3" sm="8">
          <v-card rounded="xl" elevation="0" width="100%" height="auto" style="filter: drop-shadow(0px 0px 5px rgba(52, 60, 89, 0.19));">
            <v-card-title class="font-weight-black text-h4 primary--text justify-center">Por último<strong class="secondary--text">:</strong></v-card-title>
            <v-row justify="center" align="center">
              <v-col justify="center" align="center" cols="10">
                <p class="font-weight-light text-h6 primary--text mb-n6" style="line-height: 1;">Quais negócios você teria interesse em investir?</p>
              </v-col>
              <v-col justify="center" align="center" cols="10">
                  <v-autocomplete
                      multiple
                      class="my-2"
                      v-model="interesses"
                      :items="tiposNegocio"
                      :menu-props="{ maxHeight: '400' }"
                      chips
                      filled
                      rounded
                      required
                      background-color="#e9eaed"
                      prepend-icon="  "
                      style="
                      border-radius: 15px; right:20px;
                      "
                  >
                  </v-autocomplete>
                  <p class="font-weight-light text-h6 primary--text mt-n5" style="line-height: 1;">Você gostaria de empreender no curto, médio ou longo prazo?</p>
                  <v-select
                      class="my-2"
                      v-model="empreenderPrazo"
                      :items="['Curto prazo (ainda este ano)', 'Médio prazo (em até 2 anos)', 'Longo prazo (depois de 2 anos)']"
                      :menu-props="{ maxHeight: '400' }"
                      filled
                      rounded
                      required
                      background-color="#e9eaed"
                      prepend-icon="  "
                      style="
                      border-radius: 15px; right:20px;
                      "
                  >
                  </v-select>
                   <p class="font-weight-light text-h6 primary--text mt-n5" style="line-height: 1;">Quanto você acha adequado investir em um negócio?</p>
                  <v-select
                      class="my-2"
                      v-model="investimentoAdequado"
                      :items="['Até 50 mil reais', 'De 50 mil a 300 mil reais', 'Mais de 300 mil reais']"
                      :menu-props="{ maxHeight: '400' }"
                      filled
                      rounded
                      required
                      background-color="#e9eaed"
                      prepend-icon="  "
                      style="
                      border-radius: 15px; right:20px;
                      "
                  >
                  </v-select>
              </v-col>
              <v-col v-if="perguntasAlert" justify="center" align="center" cols="9">
                <p class="font-weight-light subtitile-1 error--text mt-n9">Você deve responder todas perguntas!</p>
              </v-col>
              <v-col justify="center" align="center" cols="10">
                <v-btn @click="pageNext()" style="filter: drop-shadow(0px 0px 5px rgba(52, 60, 89, 0.7));" elevation="0" width="auto" height="50px" class="primary font-weight-light white--text text-h6 text-capitalize mt-n7" rounded>Continuar</v-btn>
              </v-col>
            </v-row>
          </v-card>
      </v-col>
    </v-row>
    <!-- perguntas fim -->
    <!-- enviar resultado inicio -->
    <v-row v-if="pageCounter==12" justify="center" align="center">
      <v-col justify="center" align="center" cols="11" md="6" lg="4" xl="3" sm="8">
          <v-card rounded="xl" elevation="0" width="100%" height="auto" style="filter: drop-shadow(0px 0px 5px rgba(52, 60, 89, 0.19));">
            <v-card-title class="font-weight-black text-h4 primary--text justify-center">Pronto<strong class="secondary--text">!</strong></v-card-title>
            <v-row justify="center" align="center">
              <v-col justify="center" align="center" cols="11">
                <p class="font-weight-light text-h6 primary--text">Mas antes precisamos de algumas informações suas para lhe enviar o resultado</p>
              </v-col>
              <v-col justify="center" align="center" cols="10">
                <v-form ref="form" class="mt-n5">
                  <v-text-field
                      class="my-2"
                      v-model="name"
                      :rules="nameRules"
                      label="Nome"
                      filled
                      dense
                      rounded
                      required
                      background-color="#e9eaed"
                      prepend-icon="  "
                      style="
                      border-radius: 15px; right:20px;
                      "
                  ></v-text-field>
                   <v-text-field
                      class="mt-n3 my-2"
                      v-model="email"
                      :rules="emailRules"
                      label="E-mail"
                      filled
                      dense
                      rounded
                      required
                      background-color="#e9eaed"
                      prepend-icon="  "
                      style="
                      border-radius: 15px; right:20px;
                      "
                  ></v-text-field>
                  <v-text-field
                      class="mt-n3 my-2"
                      v-model="celular"
                      :rules="celularRules"
                      label="Celular"
                      type="number"
                      filled
                      dense
                      rounded
                      required
                      background-color="#e9eaed"
                      prepend-icon="  "
                      style="
                      border-radius: 15px; right:20px;
                      "
                  ></v-text-field>
                </v-form>
              </v-col>
              <v-col justify="center" align="center" cols="9">
                <p class="font-weight-light subtitile-1 primary--text mt-n9">Você receberá o resultado nesse email!</p>
              </v-col>
              <v-col justify="center" align="center" cols="10">
                <v-btn :loading="loading" @click="enviarResultado" style="filter: drop-shadow(0px 0px 5px rgba(52, 60, 89, 0.7));" elevation="0" width="auto" height="50px" class="primary font-weight-light white--text text-h6 text-capitalize mt-n7" rounded>Confirmar</v-btn>
              </v-col>
            </v-row>
          </v-card>
      </v-col>
    </v-row>
    <!-- enviar resultado fim -->
    <!-- resultado inicio -->
    <v-row v-if="pageCounter==13" justify="center" align="center">
      <v-col justify="center" align="center" cols="11" md="6" lg="4" xl="3" sm="8">
          <v-card class="bg-confetti-animated" rounded="xl" elevation="0" width="100%" height="auto" style="filter: drop-shadow(0px 0px 5px rgba(52, 60, 89, 0.19));">
            <v-card-title class="font-weight-black text-h4 primary--text justify-center">Pronto<strong class="secondary--text">!</strong></v-card-title>
            <v-row justify="center" align="center">
              <v-col justify="center" align="center" cols="11">
                <p  v-if="!reenviarShow" class="font-weight-light text-h6 primary--text">O resultado foi enviado ao seu email!</p>
                <p  v-if="reenviarShow" class="font-weight-light text-h6 primary--text">Digite seu email novamente</p>
              </v-col>
              <v-col justify="center" align="center" cols="10">
                <v-btn href="/"  v-if="!reenviarShow" style="filter: drop-shadow(0px 0px 5px rgba(52, 60, 89, 0.7));" elevation="0" width="auto" height="50px" class="primary font-weight-light white--text text-h6 text-capitalize mt-n7 mb-5" rounded>Voltar ao início</v-btn>
              </v-col>
              <v-col justify="center" align="center" cols="10">
                <v-text-field
                      v-if="reenviarShow"
                      class="mt-n3 my-2"
                      v-model="email"
                      :rules="emailRules"
                      label="E-mail"
                      filled
                      dense
                      rounded
                      required
                      background-color="#e9eaed"
                      prepend-icon="  "
                      style="
                      border-radius: 15px; right:20px;
                      "
                ></v-text-field>
              </v-col>
              <v-col justify="center" align="center" cols="9">
                <p v-if="!reenviarShow" class="font-weight-light subtitile-1 primary--text mt-n12">O quê? O resultado não chegou?!</p>
              </v-col>
              <v-col justify="center" align="center" cols="10">
                <v-btn @click="apagarAnterior" :loading="loading"  v-if="!reenviarShow" style="filter: drop-shadow(0px 0px 5px rgba(52, 60, 89, 0.7));" elevation="0" width="auto" height="50px" class="primary font-weight-light white--text text-h6 text-capitalize mt-n7" rounded>Reenviar</v-btn>
              </v-col>
              <v-col justify="center" align="center" cols="10">
                <v-btn v-if="reenviarShow" :loading="loading" @click="enviarResultado" style="filter: drop-shadow(0px 0px 5px rgba(52, 60, 89, 0.7));" elevation="0" width="auto" height="50px" class="primary font-weight-light white--text text-h6 text-capitalize mt-n15" rounded>Confirmar</v-btn>
              </v-col>
            </v-row>
          </v-card>
      </v-col>
    </v-row>
    <!-- resultado fim -->

    <!-- progress bar inicio -->
    <v-card
      elevation="0"
      tile
      width="100%"
      height="78px"
      class="white"
      style="position: fixed; bottom: 0px; z-index: 3; filter: drop-shadow(0px -20px 30px rgba(52, 60, 89, 0.19));"
    >
      <v-row justify="center" align="center">
        <v-col cols="10" lg="6" xl="4" justify="center" align="center">
          <template>
            <v-progress-linear style="border-radius: 20px" class="mt-8" buffer-value="100" width="100%" height="13px" v-model="progress"></v-progress-linear>
          </template>
        </v-col>
      </v-row>
    </v-card>
    <!-- progress bar fim -->
  </div>
</template>
 
<script>
import HeaderMobile from "../components/HeaderMobile.vue";
import HeaderDesktop from "../components/HeaderDesktop.vue";
import gql from "graphql-tag";
export default {
  components: {
    HeaderMobile,
    HeaderDesktop,
  },
  data: () => ({
    desempate: 0,
    id:'',
    reenviarShow: false,
    loading: false,
    clicks: 0,
    pageCounter: 0,
    progress:0,
    pageNextAlert:0,
    tiposNegocio: [],
    interesses: '',
    empreenderPrazo: '',
    investimentoAdequado:'',
    perguntasAlert: false,
    p11:0,
    p12:0,
    p13:0,
    p14:0,
    p21:0,
    p22:0,
    p23:0,
    p24:0,
    p31:0,
    p32:0,
    p33:0,
    p34:0,
    p41:0,
    p42:0,
    p43:0,
    p44:0,
    p51:0,
    p52:0,
    p53:0,
    p54:0,
    p61:0,
    p62:0,
    p63:0,
    p64:0,
    p71:0,
    p72:0,
    p73:0,
    p74:0,
    p81:0,
    p82:0,
    p83:0,
    p84:0,
    p91:0,
    p92:0,
    p93:0,
    p94:0,
    p101:0,
    p102:0,
    p103:0,
    p104:0,
    p111:0,
    p112:0,
    p113:0,
    p114:0,
    name: "",
    nameRules: [
      (v) => !!v || "Digite seu nome",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "Digite o e-mail",
      (v) => /.+@.+\..+/.test(v) || "Este e-mail não é válido",
    ],
    celular:'',
    celularRules:[
      (v) => !!v || "Insira seu telefone de contato",
      (v) =>
        v.replace(/\D/g, "").length >= 11 ||
        "Insira um número de celular válido (com DDD)",
    ]
  }),
  methods:{
  async enviarResultado(){
      if(this.p111==4){
        this.desempate=1;
      }
      if(this.p112==4){
        this.desempate=2;
      }
      if(this.p113==4){
        this.desempate=3;
      }
      if(this.p114==4){
        this.desempate=4;
      }
      this.loading=true;
      try {
        fbq('track', 'Lead');
      } catch (error) {
        console.error(error)
      }
      try {
          let mutation = await this.$apollo.mutate({
              mutation: gql`
                mutation MyMutation($answers: json!, $desempate: Int!, $email: String!, $interests: json!, $name: String!, $period: String!, $range: String!, $whatsapp: String!) {
                  CreateProfileTest(answers: $answers, desempate: $desempate, email: $email, interests: $interests, name: $name, period: $period, range: $range, whatsapp: $whatsapp) {
                    id
                  }
                }
              `,
              variables: {
                  desempate: this.desempate,
                  interests: this.interesses,
                  range: this.investimentoAdequado,
                  period: this.empreenderPrazo,
                  name: this.name,
                  whatsapp: this.celular,
                  email: this.email,
                  answers: {
                    "q1": this.p11,
                    "q2": this.p12,
                    "q3": this.p13,
                    "q4": this.p14,
                    "q5": this.p21,
                    "q6": this.p22,
                    "q7": this.p23,
                    "q8": this.p24,
                    "q9": this.p31,
                    "q10": this.p32,
                    "q11": this.p33,
                    "q12": this.p34,
                    "q13": this.p41,
                    "q14": this.p42,
                    "q15": this.p43,
                    "q16": this.p44,
                    "q17": this.p51,
                    "q18": this.p52,
                    "q19": this.p53,
                    "q20": this.p54,
                    "q21": this.p61,
                    "q22": this.p62,
                    "q23": this.p63,
                    "q24": this.p64,
                    "q25": this.p71,
                    "q26": this.p72,
                    "q27": this.p73,
                    "q28": this.p74,
                    "q29": this.p81,
                    "q30": this.p82,
                    "q31": this.p83,
                    "q32": this.p84,
                    "q33": this.p91,
                    "q34": this.p92,
                    "q35": this.p93,
                    "q36": this.p94,
                    "q37": this.p101,
                    "q38": this.p102,
                    "q39": this.p103,
                    "q40": this.p104,
                  }
              },
          });
          this.id=mutation.data.CreateProfileTest.id;
          this.loading=false;
          this.reenviarShow=false;
          this.pageCounter=13;
      } catch (error) {
        console.log("error: ", error);
        this.loading=false;
      }
    },
    async apagarAnterior(){
      this.loading=true;
      try {
          let mutation = await this.$apollo.mutate({
              mutation: gql`
                  mutation MyMutation($email: String!, $name: String!, $whatsapp:
                  String!) {
                    EraseWrongEmail(email: $email, name: $name, whatsapp: $whatsapp) {
                      num_of_rows
                    }
                  }

              `,
              variables: {
                  email: this.email,
                  name: this.name,
                  whatsapp: this.celular,
              },
          });
          this.loading=false;
          this.reenviarShow=true;
          this.email="";
      } catch (error) {
        // console.log(mutation.data);
        console.log("error: ", error);
        this.loading=false;
      }
    },
    ajustarRespostas(){
      for(var i=0; i<this.interesses.length; i++){
        this.interesses[i]=this.interesses[i].charAt(0).toLowerCase() + this.interesses[i].substr(1);
      };
      this.interesses=JSON.stringify(this.interesses);
      switch(this.empreenderPrazo){
        case 'Curto prazo (ainda este ano)':
          this.empreenderPrazo='curto prazo';
          break;
        case 'Médio prazo (em até 2 anos)':
          this.empreenderPrazo='medio prazo';
          break;
        case 'Longo prazo (depois de 2 anos)':
          this.empreenderPrazo='longo prazo';
          break;
      };
      switch(this.investimentoAdequado){
        case 'Até 50 mil reais':
          this.investimentoAdequado='0-50k';
          break;
        case 'De 50 mil a 300 mil reais':
          this.investimentoAdequado='50k-300k';
          break;
        case 'Mais de 300 mil reais':
          this.investimentoAdequado='300k+';
          break;
      };
    },
    async getTipos(){
      this.loading=true;
      try{
        let query=await this.$apollo.query({
          query: gql `
            query MyQuery {
                GetCompanyTypes {
                  tipos
                }
              }
            `
        });
        this.tiposNegocio=query.data.GetCompanyTypes.tipos;
      } catch(error){
        console.log(error)
      }
      this.tiposNegocio.sort();
      for(var i=0; i<this.tiposNegocio.length; i++){
        this.tiposNegocio[i]=this.tiposNegocio[i].charAt(0).toUpperCase() + this.tiposNegocio[i].substr(1);
      }
      this.loading=false;
    },
    clickou(p){
      if(p==0&&this.clicks<=3){
        p=4-this.clicks
        this.clicks++;
      } else if(p==5-this.clicks){
        p=0;
        this.clicks--;
      }
      return p;
    },
    pageNext(){
      if(this.pageCounter===11){
        if(this.interesses!=='' && this.empreenderPrazo!=='' && this.investimentoAdequado!==''){
          this.pageCounter++;
          this.perguntasAlert=false;
          this.ajustarRespostas();
        } else{
          this.perguntasAlert=true;
        }
      } else{
        if(this.clicks===4){
          this.pageCounter++;
          this.progress=(this.pageCounter/11)*100;
          this.clicks=0;
          this.pageNextAlert=0;
        }
        else{
          this.pageNextAlert=1;
        }
      }
    },
 
  },

};
</script>

<style scoped>
.bg-confetti-animated {
    background-repeat: repeat-x  !important;
    background-position: top -10px center  !important;
    background-image: url("https://s3.amazonaws.com/buyco.assets/images/conff.svg");
    @media (prefers-reduced-motion) {
        background-image: url("https://s3.amazonaws.com/buyco.assets/images/conff2.svg")  !important;
    }

}
</style>