import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    data: {
      calculating: true
    }
  },
  mutations: {
    save1(context, payload) {		
      this.state.data = { ...this.state.data, ...payload }
    }
  },
  actions: {
    save(store, payload) {
      store.commit('save1', payload)
    }
  },
  modules: {
  }
})
