var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12","justify":"center","align":"center"}},[_c('p',{staticClass:"font-weight-black text-h4 primary--text mt-10"},[_vm._v(" Sobre os perfis"),_c('strong',{staticClass:"secondary--text"},[_vm._v(":")])])]),_c('v-col',{attrs:{"cols":"10","sm":"5","lg":"3","xl":"2"}},[_c('v-card',{staticClass:"nada",class:{ rotateCard: _vm.rotate1, white: !_vm.content1, error: _vm.content1 },staticStyle:{"cursor":"pointer"},attrs:{"hover":"","rounded":"xl","width":"100%","height":"340px"},on:{"click":function($event){_vm.rotate1 = true;
          _vm.contentChange(1);}}},[(_vm.content1)?_c('div',[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"10","justify":"center","align":"center"}},[_c('v-img',{staticClass:"mb-5",staticStyle:{"top":"0px"},attrs:{"src":require("../assets/executor.png"),"height":"180px","width":"180px"}})],1),_c('v-col',{attrs:{"cols":"10","justify":"center","align":"center"}},[_c('p',{staticClass:"font-weight-bold text-h3 mt-n7",staticStyle:{"color":"#980b1c"}},[_vm._v(" Executor ")]),_c('p',{staticClass:"font-weight-thin subtitle-1 white--text mt-n5"},[_vm._v(" Clique para saber mais ")])])],1)],1):_vm._e(),(!_vm.content1)?_c('div',{staticStyle:{"transform":"rotateY(180deg)"}},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12","justify":"center","align":"center"}},[_c('p',{staticClass:"font-weight-light text-h3 error--text mt-n1"},[_vm._v(" Executor ")])]),_c('v-col',{attrs:{"cols":"12","justify":"center","align":"left"}},[_c('p',{staticClass:"mr-4 font-weight-light text-h6 grey--text mt-n9 ml-4 text-justify pr-2",staticStyle:{"line-height":"120%"}},[_vm._v(" Pessoas ativas, competitivas e dinâmicas;​ Não têm medo de assumir riscos e de enfrentar desafios;​"),_c('br'),_vm._v(" Apreciam desafios e os obstáculos os estimulam a agir cada vez mais. Tendem a primeiro executar, para depois pensar em como fazê-lo.​ ")])])],1)],1):_vm._e()])],1),_c('v-col',{attrs:{"cols":"10","sm":"5","lg":"3","xl":"2"}},[_c('v-card',{staticClass:"nada",class:{
          rotateCard: _vm.rotate2,
          white: !_vm.content2,
          secondary: _vm.content2,
        },staticStyle:{"cursor":"pointer"},attrs:{"hover":"","rounded":"xl","width":"100%","height":"340px"},on:{"click":function($event){_vm.rotate2 = true;
          _vm.contentChange(2);}}},[(_vm.content2)?_c('div',[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"10","justify":"center","align":"center"}},[_c('v-img',{staticClass:"my-2 mb-5",attrs:{"src":require("../assets/comunicador.png"),"height":"165px","width":"165px"}})],1),_c('v-col',{attrs:{"cols":"10","justify":"center","align":"center"}},[_c('p',{staticClass:"font-weight-bold mt-n7",staticStyle:{"color":"#e37f22","font-size":"2.5em"}},[_vm._v(" Comunicador ")]),_c('p',{staticClass:"font-weight-thin subtitle-1 white--text mt-n7"},[_vm._v(" Clique para saber mais ")])])],1)],1):_vm._e(),(!_vm.content2)?_c('div',{staticStyle:{"transform":"rotateY(180deg)"}},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12","justify":"center","align":"center"}},[_c('p',{staticClass:"font-weight-light text-h3 secondary--text mt-n1"},[_vm._v(" Comunicador ")])]),_c('v-col',{attrs:{"cols":"12","justify":"center","align":"left"}},[_c('p',{staticClass:"mr-2 font-weight-light text-h6 grey--text mt-n9 ml-4 mr-2 text-justify pr-2",staticStyle:{"line-height":"120%"}},[_vm._v(" Extrovertidos, falantes, ativos e não apreciam monotonias, mas se adaptam com facilidade;​ Gostam de trabalhos que envolvam movimentação e autonomia;​ Precisam do contato interpessoal e de um ambiente harmonioso. ")])])],1)],1):_vm._e()])],1)],1),_c('v-row',{staticClass:"mt-5",attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"10","sm":"5","lg":"3","xl":"2"}},[_c('v-card',{staticClass:"nada",class:{ rotateCard: _vm.rotate3, white: !_vm.content3, success: _vm.content3 },staticStyle:{"cursor":"pointer"},attrs:{"hover":"","rounded":"xl","width":"100%","height":"340px"},on:{"click":function($event){_vm.rotate3 = true;
          _vm.contentChange(3);}}},[(_vm.content3)?_c('div',[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"10","justify":"center","align":"center"}},[_c('v-img',{staticClass:"mt-n6",attrs:{"src":require("../assets/planejador.png"),"height":"220px","width":"220px"}})],1),_c('v-col',{attrs:{"cols":"10","justify":"center","align":"center"}},[_c('p',{staticClass:"font-weight-bold text-h3 mt-n7",staticStyle:{"color":"#285b5c"}},[_vm._v(" Planejador ")]),_c('p',{staticClass:"font-weight-thin subtitle-1 white--text mt-n5"},[_vm._v(" Clique para saber mais ")])])],1)],1):_vm._e(),(!_vm.content3)?_c('div',{staticStyle:{"transform":"rotateY(180deg)"}},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12","justify":"center","align":"center"}},[_c('p',{staticClass:"font-weight-light text-h3 success--text mt-n1"},[_vm._v(" Planejador ")])]),_c('v-col',{attrs:{"cols":"12","justify":"center","align":"left"}},[_c('p',{staticClass:"mr-2 font-weight-light text-h6 grey--text mt-n9 ml-4 text-justify pr-2",staticStyle:{"line-height":"120%"}},[_vm._v(" Pessoas calmas, tranquilas, prudentes e autocontroladas;​ Gostam de rotina e atuam em conformidade com as normas e regras estabelecidas;​ Decidem sem pressão e,com bom senso; São pacientes, observadores, passivos e têm boa memória, planejam a melhor forma de executar uma tarefa antes de iniciá-la.​ ")])])],1)],1):_vm._e()])],1),_c('v-col',{attrs:{"cols":"10","sm":"5","lg":"3","xl":"2"}},[_c('v-card',{staticClass:"nada",class:{ rotateCard: _vm.rotate4, white: !_vm.content4, analista: _vm.content4 },staticStyle:{"cursor":"pointer"},attrs:{"hover":"","rounded":"xl","width":"100%","height":"340px"},on:{"click":function($event){_vm.rotate4 = true;
          _vm.contentChange(4);}}},[(_vm.content4)?_c('div',[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"10","justify":"center","align":"center"}},[_c('v-img',{staticClass:"mt-n2 mb-6",attrs:{"src":require("../assets/analista.png"),"height":"180px","width":"180px"}})],1),_c('v-col',{attrs:{"cols":"10","justify":"center","align":"center"}},[_c('p',{staticClass:"font-weight-bold text-h3 mt-n7 primary--text"},[_vm._v(" Analista ")]),_c('p',{staticClass:"font-weight-thin subtitle-1 white--text mt-n5"},[_vm._v(" Clique para saber mais ")])])],1)],1):_vm._e(),(!_vm.content4)?_c('div',{staticStyle:{"transform":"rotateY(180deg)"}},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12","justify":"center","align":"center"}},[_c('p',{staticClass:"font-weight-light text-h3 primary--text mt-n1"},[_vm._v(" Analista ")])]),_c('v-col',{attrs:{"cols":"12","justify":"center","align":"left"}},[_c('p',{staticClass:"font-weight-light text-h6 grey--text mt-n9 ml-4 mr-2 text-justify pr-2",staticStyle:{"line-height":"120%"}},[_vm._v(" Preocupados, detalhistas, rígidos, porém calmos;​ Tendem a ser pessoas observadoras e que falam menos;​ Foco, inteligência e perfeccionismo, são algumas das suas principais características.​ ")])])],1)],1):_vm._e()])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }