import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import pt from 'vuetify/lib/locale/pt';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
      options: {
        customProperties: true,
      },
    themes: {
      light: {
        primary: "#343c59",
        secondary: "#FFC107",
        accent: "#E4E9ED",
        error: "#FF5252",
        info: "#059EB3",
        success: "#498485",
        warning: "#FFC107"
      },
    },
  },
    lang: {
      locales: { pt },
      current: 'pt',
    },
});
