<template>
  <div>
    <v-row justify="center" align="center">
      <v-col cols="12" justify="center" align="center">
        <p class="font-weight-black text-h4 primary--text mt-10">
          Sobre os perfis<strong class="secondary--text">:</strong>
        </p>
      </v-col>
      <!-- executor -->
      <v-col cols="10" sm="5" lg="3" xl="2">
        <v-card
          hover
          @click="
            rotate1 = true;
            contentChange(1);
          "
          style="cursor: pointer"
          :class="{ rotateCard: rotate1, white: !content1, error: content1 }"
          class="nada"
          rounded="xl"
          width="100%"
          height="340px"
        >
          <div v-if="content1">
            <v-row justify="center" align="center">
              <v-col cols="10" justify="center" align="center">
                <v-img
                  class="mb-5"
                  src="../assets/executor.png"
                  height="180px"
                  width="180px"
                  style="top: 0px"
                ></v-img>
              </v-col>
              <v-col cols="10" justify="center" align="center">
                <p
                  class="font-weight-bold text-h3 mt-n7"
                  style="color: #980b1c"
                >
                  Executor
                </p>
                <p class="font-weight-thin subtitle-1 white--text mt-n5">
                  Clique para saber mais
                </p>
              </v-col>
            </v-row>
          </div>
          <div v-if="!content1" style="transform: rotateY(180deg)">
            <v-row justify="center" align="center">
              <v-col cols="12" justify="center" align="center">
                <p class="font-weight-light text-h3 error--text mt-n1">
                  Executor
                </p>
              </v-col>
              <v-col cols="12" justify="center" align="left">
                <p
                  class="mr-4 font-weight-light text-h6 grey--text mt-n9 ml-4 text-justify pr-2"
                  style="line-height: 120%"
                >
                  Pessoas ativas, competitivas e dinâmicas;​ Não têm medo de
                  assumir riscos e de enfrentar desafios;​<br />
                  Apreciam desafios e os obstáculos os estimulam a agir cada vez
                  mais. Tendem a primeiro executar, para depois pensar em como
                  fazê-lo.​
                </p>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
      <!-- comunicador -->
      <v-col cols="10" sm="5" lg="3" xl="2">
        <v-card
          hover
          @click="
            rotate2 = true;
            contentChange(2);
          "
          style="cursor: pointer"
          :class="{
            rotateCard: rotate2,
            white: !content2,
            secondary: content2,
          }"
          class="nada"
          rounded="xl"
          width="100%"
          height="340px"
        >
          <div v-if="content2">
            <v-row justify="center" align="center">
              <v-col cols="10" justify="center" align="center">
                <v-img
                  class="my-2 mb-5"
                  src="../assets/comunicador.png"
                  height="165px"
                  width="165px"
                  style=""
                ></v-img>
              </v-col>
              <v-col cols="10" justify="center" align="center">
                <p
                  class="font-weight-bold mt-n7"
                  style="color: #e37f22; font-size: 2.5em"
                >
                  Comunicador
                </p>
                <p class="font-weight-thin subtitle-1 white--text mt-n7">
                  Clique para saber mais
                </p>
              </v-col>
            </v-row>
          </div>
          <div v-if="!content2" style="transform: rotateY(180deg)">
            <v-row justify="center" align="center">
              <v-col cols="12" justify="center" align="center">
                <p class="font-weight-light text-h3 secondary--text mt-n1">
                  Comunicador
                </p>
              </v-col>
              <v-col cols="12" justify="center" align="left">
                <p
                  class="mr-2 font-weight-light text-h6 grey--text mt-n9 ml-4 mr-2 text-justify pr-2"
                  style="line-height: 120%"
                >
                  Extrovertidos, falantes, ativos e não apreciam monotonias, mas
                  se adaptam com facilidade;​ Gostam de trabalhos que envolvam
                  movimentação e autonomia;​ Precisam do contato interpessoal e
                  de um ambiente harmonioso.
                </p>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center" align="center" class="mt-5">
      <!-- planejador -->
      <v-col cols="10" sm="5" lg="3" xl="2">
        <v-card
          hover
          @click="
            rotate3 = true;
            contentChange(3);
          "
          style="cursor: pointer"
          :class="{ rotateCard: rotate3, white: !content3, success: content3 }"
          class="nada"
          rounded="xl"
          width="100%"
          height="340px"
        >
          <div v-if="content3">
            <v-row justify="center" align="center">
              <v-col cols="10" justify="center" align="center">
                <v-img
                  class="mt-n6"
                  src="../assets/planejador.png"
                  height="220px"
                  width="220px"
                  style=""
                ></v-img>
              </v-col>
              <v-col cols="10" justify="center" align="center">
                <p
                  class="font-weight-bold text-h3 mt-n7"
                  style="color: #285b5c"
                >
                  Planejador
                </p>
                <p class="font-weight-thin subtitle-1 white--text mt-n5">
                  Clique para saber mais
                </p>
              </v-col>
            </v-row>
          </div>
          <div v-if="!content3" style="transform: rotateY(180deg)">
            <v-row justify="center" align="center">
              <v-col cols="12" justify="center" align="center">
                <p class="font-weight-light text-h3 success--text mt-n1">
                  Planejador
                </p>
              </v-col>
              <v-col cols="12" justify="center" align="left">
                <p
                  class="mr-2 font-weight-light text-h6 grey--text mt-n9 ml-4 text-justify pr-2"
                  style="line-height: 120%"
                >
                  Pessoas calmas, tranquilas, prudentes e autocontroladas;​
                  Gostam de rotina e atuam em conformidade com as normas e
                  regras estabelecidas;​ Decidem sem pressão e,com bom senso;
                  São pacientes, observadores, passivos e têm boa memória,
                  planejam a melhor forma de executar uma tarefa antes de
                  iniciá-la.​
                </p>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
      <!-- analista -->
      <v-col cols="10" sm="5" lg="3" xl="2">
        <v-card
          hover
          @click="
            rotate4 = true;
            contentChange(4);
          "
          style="cursor: pointer"
          :class="{ rotateCard: rotate4, white: !content4, analista: content4 }"
          class="nada"
          rounded="xl"
          width="100%"
          height="340px"
        >
          <div v-if="content4">
            <v-row justify="center" align="center">
              <v-col cols="10" justify="center" align="center">
                <v-img
                  class="mt-n2 mb-6"
                  src="../assets/analista.png"
                  height="180px"
                  width="180px"
                  style=""
                ></v-img>
              </v-col>
              <v-col cols="10" justify="center" align="center">
                <p class="font-weight-bold text-h3 mt-n7 primary--text">
                  Analista
                </p>
                <p class="font-weight-thin subtitle-1 white--text mt-n5">
                  Clique para saber mais
                </p>
              </v-col>
            </v-row>
          </div>
          <div v-if="!content4" style="transform: rotateY(180deg)">
            <v-row justify="center" align="center">
              <v-col cols="12" justify="center" align="center">
                <p class="font-weight-light text-h3 primary--text mt-n1">
                  Analista
                </p>
              </v-col>
              <v-col cols="12" justify="center" align="left">
                <p
                  class="font-weight-light text-h6 grey--text mt-n9 ml-4 mr-2 text-justify pr-2"
                  style="line-height: 120%"
                >
                  Preocupados, detalhistas, rígidos, porém calmos;​ Tendem a ser
                  pessoas observadoras e que falam menos;​ Foco, inteligência e
                  perfeccionismo, são algumas das suas principais
                  características.​
                </p>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    rotate1: false,
    content1: true,
    rotate2: false,
    content2: true,
    rotate3: false,
    content3: true,
    rotate4: false,
    content4: true,
  }),
  methods: {
    contentChange(n) {
      if (n === 1) {
        setTimeout(() => {
          this.content1 = false;
        }, 300);
      }
      if (n === 2) {
        setTimeout(() => {
          this.content2 = false;
        }, 300);
      }
      if (n === 3) {
        setTimeout(() => {
          this.content3 = false;
        }, 300);
      }
      if (n === 4) {
        setTimeout(() => {
          this.content4 = false;
        }, 300);
      }
    },
  },
};
</script>

<style scoped>
.analista {
  background-color: #4b5781;
}
.nada:focus {
  color: transparent;
}
.rotateCard {
  transform: rotateY(180deg);
  transition: transform 0.8s;
  transform-style: preserve-3d;
  perspective: 1000px;
}
</style>
