<template>
  <v-app>
    <meta name="viewport" content="height=device-height, 
                      width=device-width, initial-scale=1.0, 
                      minimum-scale=1.0, maximum-scale=1.0, 
                      user-scalable=no, target-densitydpi=device-dpi">
    <AppBarPerfil/>
    
    <v-sheet height="100%" width="100%" color="#F5F6FA">
      <router-view></router-view>
      <v-sheet height="150px" color="#F5F6FA"></v-sheet>
    </v-sheet>

    <BuycoFooter/>
    <!-- <v-fab-transition>
      <v-btn
        color="#343C59A6"
        fab
        fixed
        large
        dark
        bottom
        right
        elevation="4"
        style="backdrop-filter: blur(2px)"
        @click="whats()"
      >
        <v-icon large>mdi-whatsapp</v-icon>
      </v-btn>
    </v-fab-transition> -->
  </v-app>
</template>

<script>
import BuycoFooter from "./components/BuycoFooter.vue"
import AppBarPerfil from "./components/AppBarPerfil.vue"
export default {
  name: "App",

  components: {
    BuycoFooter,
    AppBarPerfil,
  },

  data: () => ({
    links: [
      "https://www.facebook.com/buycobrasil/",
      "https://www.youtube.com/channel/UCWLwR43h9Qgk3SHUJc7J87Q",
      "https://www.linkedin.com/company/buyco-brasil/",
      "https://www.instagram.com/buycobrasil/",
    ],
    icons: ["mdi-facebook", "mdi-youtube", "mdi-linkedin", "mdi-instagram"],
    images: [
      "http://www.escoladeimagem.com.br/wp-content/uploads/download-1.jpeg",
      "https://img.freepik.com/fotos-gratis/feche-o-retrato-de-um-homem-africano-serio-e-bonito-com-pele-limpa-e-saudavel-vestindo-uma-camiseta-branca-casual-posando-isolado-contra-uma-parede-cinza-com-espaco-de-copia-para-seu-conteudo-promocional_273609-6064.jpg?size=626&ext=jpg",
      "https://cdn.pixabay.com/photo/2018/04/12/17/18/portrait-3314106_960_720.jpg",
    ],
    opinions: [
      "Eu amo a buyco!!!!!! ela é demais!",
      "sinceramente? a buyco é a melhor que existe!!",
      "Não consigo imaginar o que seria da minha vida sem buyco!!!!!!! uhuuuuul",
    ],
    names: ["Vitória", "Reginaldo", "BuyCo"],
    surnames: ["Régia", "Naldoregi", "Da Silva"],
    opinioes: 0,
    carousel: 0,
    carouselDesktop: 0,
    //
  }),
  methods: {
    whats() {
      window.open(
        `https://api.whatsapp.com/send?phone=+5531984093097`,
        "_blank"
      );
    },
    open() {
      window.open("https://www.buyco.com.br/blog/", "_blank");
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rozha+One&display=swap");

.main {
  background-color: #e4e9ed;
  background-attachment: fixed;
}
body {
  font-family: "Poppins", sans-serif;
}


</style>